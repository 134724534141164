import i18n from '../src/i18n'

export const formatPrice = (num) => {
    let usd = ''
    if (i18n.language !== 'en') usd = ' USD'

    if (!num || isNaN(num)) return 0
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + usd
}

export const formatPriceNoUsd = (num) => {
    if (!num || isNaN(num)) return 0
    return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}