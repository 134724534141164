import useSWR from 'swr'
import Loader from '../../components/Loader'
import { adminUid } from '../../components/adminComponents/getData'
import { signInCheck } from '../../utils/signInCheck'
import PowerCoinzItem from './PowerCoinzItem'
import { useEffect, useState } from 'react'

const fetcher = (url) => fetch(url).then((res) => res.json())

const PowerCoinzAdmin = () => {
    const { status, auth } = signInCheck()

    const [searchTerm, setSearchTerm] = useState('')

    const url = 'https://data.powershotz.com/php/powercoinz/getAllAccounts.php'

    const { data, error, isLoading } = useSWR(url, fetcher, { refreshInterval: 1000 })

    if (
        status === 'success' &&
        auth &&
        auth.uid !== adminUid
    ) {
        return (
            <h2 style={styles.bold}>
                You are not authorized to use this page
            </h2>
        )
    }

    if (!data || isLoading) return <Loader />
    if (error) return <h2 style={styles.bold}>Error</h2>

    return (
        <div style={styles.main}>
            <button
                style={{ marginTop: 20, marginBottom: -20 }}
                onClick={() => window.history.go(-1)}
            >
                go back
            </button>

            <h1 style={styles.bold}>
                PowerCoinz Admin
                ({data.length})
            </h1>
            <input
                type='text'
                value={searchTerm}
                placeholder='Search'
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <button
                style={styles.button}
                onClick={() => setSearchTerm('')}
            >
                clear
            </button>

            {data &&
                data.filter(x => (
                    x.email.includes(searchTerm) ||
                    (x.name && x.name.includes(searchTerm))
                )).map(i => (
                    <PowerCoinzItem
                        item={i}
                        key={i.id}
                    />
                ))
            }
        </div>
    )
}

const styles = {
    main: {
        width: '100%',
        textAlign: 'center'
    },
    bold: {
        width: '100%',
        textAlign: 'center',
        fontFamily: 'open-sans-bold',
        marginTop: 40,
    },
    button: {
        transform: 'scale(0.7)',
    }
}

export default PowerCoinzAdmin