import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_CART,
  ADD_TO_SAVED_LIST,
  REMOVE_FROM_SAVED_LIST,
  SET_SAVED,
  CHECK_CART,
  CLEAR_CART,
} from '../actions/cart'
import { ADD_ORDER } from '../actions/orders'

const initialState = {
  items: [],
  savedItems: [],
  cartMessage: null,
  checkCartMessage: null,
}

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CART:
      return {
        ...state,
        items: [],
      }
    case CHECK_CART:
      return {
        ...state,
        checkCartMessage: action.checkCartMessage,
      }
    case SET_SAVED:
      return {
        ...state,
        savedItems: action.savedItems,
      }
    case SET_CART:
      return {
        ...state,
        items: action.items,
      }
    case ADD_TO_SAVED_LIST:
      return {
        ...state,
        savedItems: action.savedItems,
        cartMessage: action.cartMessage,
      }
    case REMOVE_FROM_SAVED_LIST:
      return {
        ...state,
        savedItems: action.savedItems,
      }
    case ADD_TO_CART:
      return {
        ...state,
        items: action.items,
        cartMessage: action.cartMessage,
      }
    case REMOVE_FROM_CART:
      return {
        ...state,
        items: action.items,
      }
    case ADD_ORDER:
      // clears cart
      return {
        ...state,
        items: [],
      }
    default:
      return state
  }
}

export default cartReducer
