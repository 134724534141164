import { useState } from 'react'
import { handleError, handleGenInfo } from '../../components/Toast/toastComp'

const PowerCoinzItem = (props) => {
    const { item } = props

    const [inputAmount, setInputAmount] = useState('')
    const [open, setOpen] = useState(false)

    const balance = parseFloat(item.balance)

    const handleCredit = (e) => {

        e.preventDefault()
        const url = 'https://data.powershotz.com/php/powercoinz/creditAccount.php'

        if (confirm('Are you sure?') && inputAmount > 0) {
            const data = {
                email: item.email,
                password: item.password,
                amount: inputAmount
            }

            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
            })
                .then((res) => {
                    if (res.status === 200) {
                        handleGenInfo(`Account has been credited $${inputAmount}`)
                        setInputAmount('')
                    } else {
                        handleError('Error')
                        alert('There was a problem crediting PowerCoinz account.')
                    }
                })
                .catch((e) => {
                    // if error return false
                    console.log(`Error crediting PowerCoinz account\n${e.message}`)
                    handleError('Error')
                    alert('Server error.')
                })
        }
    }

    const handleDebit = (e) => {
        e.preventDefault()
        const url = 'https://data.powershotz.com/php/powercoinz/debitAccount.php'

        if (confirm('Are you sure?') && inputAmount > 0) {
            const data = {
                email: item.email,
                password: item.password,
                amount: inputAmount
            }

            fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
            })
                .then((res) => {
                    if (res.status === 200) {
                        handleGenInfo(`Account has been debited $${inputAmount}`)
                        setInputAmount('')
                    } else {
                        handleError('Error')
                        alert('There was a problem debiting PowerCoinz account.')
                    }
                })
                .catch((e) => {
                    // if error return false
                    console.log(`Error debiting PowerCoinz account\n${e.message}`)
                    handleError('Error')
                    alert('Server error.')
                })
        }
    }

    const memberDate = new Date(item.date)
    const formattedMemberDate = memberDate.toLocaleDateString('en-US')

    return (
        <div style={styles.main}>
            <h3 style={styles.email}>{item.email}</h3>

            {!open &&
                <p style={{ margin: 0 }}>
                    ${balance.toFixed(2)} {item.currency}
                </p>
            }

            <button
                style={{ transform: 'scale(0.75)' }}
                onClick={() => setOpen(!open)}>
                {open
                    ? 'close'
                    : 'open'
                }
            </button>

            {open &&
                <div>
                    <p style={styles.p}>
                        <span>name:</span>
                        <span style={styles.span}>{item.name}</span>
                    </p>

                    <p style={styles.p}>
                        <span>password: </span>
                        <span style={styles.span}>{item.password}</span>
                    </p>

                    <p style={styles.p}>
                        <span>member since:</span>
                        <span style={styles.span}>{formattedMemberDate}</span>
                    </p>

                    <p style={styles.p}>
                        <span>last updated:</span>
                        <span style={styles.span}>{item.updated}</span>
                    </p>

                    <p style={styles.p}>
                        <span>last amount:</span>
                        <span style={styles.span}>{item.amount}</span>
                    </p>

                    <p style={styles.p}>
                        <span>last event_id:</span>
                        <span style={styles.span}>{item.event_id}</span>
                    </p>

                    <p style={styles.p}>
                        <span>last payment_id:</span>
                        <span style={styles.span}>{item.payment_id}</span>
                    </p>

                    <p style={styles.p}>
                        <span>balance:</span>
                        <span style={styles.span}>${balance.toFixed(2)} {item.currency}</span>
                    </p>

                    <form style={styles.form}>
                        <div>
                            $<input
                                type='number'
                                name='amount'
                                placeholder='update balance'
                                style={styles.input}
                                value={inputAmount}
                                onChange={(e) => setInputAmount(e.target.value)}
                            />

                            <span>
                                {item.currency}
                            </span>
                        </div>

                        <input
                            type='submit'
                            value='Credit'
                            onClick={handleCredit}
                            style={{ ...styles.submit, backgroundColor: 'green' }}
                        />

                        <input
                            type='submit'
                            value='Debit'
                            onClick={handleDebit}
                            style={{ ...styles.submit, backgroundColor: 'red', }}
                        />
                    </form>
                </div>
            }
        </div>
    )
}

const styles = {
    main: {
        backgroundColor: 'white',
        borderRadius: 5,
        fontFamily: 'open-sans',
        boxShadow: '1px 2px 5px gray',
        margin: 'auto',
        marginTop: 25,
        maxWidth: 320,
        padding: 30,
        paddingTop: 1,
        paddingBottom: 10,
    },
    email: {
        backgroundColor: '#ededed',
        padding: 10,
        marginBottom: 0,
    },
    p: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '0.9rem',
        margin: 0,
        borderBottom: '1px solid gray',
        color: 'gray',
    },
    span: {
        maxWidth: '50%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: 'black',
    },
    form: {
        marginTop: 20,
    },
    input: {
        padding: 5,
        margin: 5,
    },
    submit: {
        margin: 10,
        marginTop: 15,
        color: 'white',
        border: 'none',
        padding: 8,
        width: 75,
        borderRadius: 5,
        cursor: 'pointer',
    },
}

export default PowerCoinzItem