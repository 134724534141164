import isDownload from '../../components/isDownload'

export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const SET_CART = 'SET_CART'
export const ADD_TO_SAVED_LIST = 'ADD_TO_SAVED_LIST'
export const REMOVE_FROM_SAVED_LIST = 'REMOVE_FROM_SAVED_LIST'
export const SET_SAVED = 'SET_SAVED'
export const CHECK_CART = 'CHECK_CART'
export const CLEAR_CART = 'CLEAR_CART'

const getAsyncItems = async () => {
  const cartData = localStorage.getItem('cart')
  const transformedData =
    cartData !== null ? JSON.parse(cartData) : { items: [] }
  return transformedData.items
}

const getAsyncSavedItems = async () => {
  const savedData = localStorage.getItem('saved')
  const transformedData =
    savedData !== null ? JSON.parse(savedData) : { savedItems: [] }
  return transformedData.savedItems
}

export const addToCart = (addedProduct) => {
  return async (dispatch, getState) => {
    let items = items = await getAsyncItems()

    const option = addedProduct.options
      ? addedProduct.option
        ? addedProduct.option
        : ''
      : ''

    // is item already in cart?
    const cartItem = items.find((prod) => {
      return prod.product.id === addedProduct.id &&
        prod.option === option
    })

    // if item is already in cart, find index
    const itemIndex = !!cartItem
      ? items.findIndex((prod) => prod.product.id === addedProduct.id && prod.option === option)
      : false

    if (!!cartItem) {
      // item is already in cart
      const updatedCartItem = {
        product: { id: cartItem.product.id },
        quantity: cartItem.quantity + 1,
        option: cartItem.option ? cartItem.option : '',
      }

      // insert updated cart item into index of old item
      items[itemIndex] = updatedCartItem

      // save to local storage
      localStorage.setItem(
        'cart',
        JSON.stringify({
          items: items,
        })
      )

      // save to store
      dispatch({
        type: ADD_TO_CART,
        items: items,
        cartMessage: null,
      })
    } else {
      // item is not in cart
      const newCartItem = {
        product: { id: addedProduct.id },
        quantity: 1,
        option: option
      }

      // save to local storage
      localStorage.setItem(
        'cart',
        JSON.stringify({
          items: items.concat(newCartItem),
        })
      )

      // save to store
      dispatch({
        type: ADD_TO_CART,
        items: items.concat(newCartItem),
        cartMessage: null,
      })
    }
  }
}

export const addItemToCart = (item) => {
  return async (dispatch, getState) => {
    let items = items = await getAsyncItems()

    // is item already in cart?
    const cartItem = items.find((i) => i.product.id === item.product.id && i.option === item.option)

    // if item is already in cart, find index
    const itemIndex = !!cartItem
      ? items.findIndex((i) => i.product.id === item.product.id && i.option === item.option)
      : false

    if (cartItem) {
      // item is already in cart
      const updatedCartItem = {
        product: { id: cartItem.product.id },
        quantity: cartItem.quantity + 1,
        option: cartItem.option ? cartItem.option : '',
      }

      // insert updated cart item into index of old item
      items[itemIndex] = updatedCartItem

      // save to local storage
      localStorage.setItem(
        'cart',
        JSON.stringify({
          items: items,
        })
      )

      // save to store
      dispatch({
        type: ADD_TO_CART,
        items: items,
        cartMessage: null,
      })
    } else {
      // item is not in cart
      const newCartItem = {
        product: { id: item.product.id },
        quantity: 1,
        option: item.option
      }

      // save to local storage
      localStorage.setItem(
        'cart',
        JSON.stringify({
          items: items.concat(newCartItem),
        })
      )

      // save to store
      dispatch({
        type: ADD_TO_CART,
        items: items.concat(newCartItem),
        cartMessage: null,
      })
    }
  }
}

export const removeItemFromCart = (item) => {
  return async (dispatch, getState) => {
    let items = await getAsyncItems()

    const option = item.option
      ? item.option
      : ''

    // find item in cart
    const selectedCartItem = items.find((i) => i.product.id === item.product.id && i.option === item.option)

    // find index
    const cartItemIndex = items.findIndex((i) => i.product.id === item.product.id && i.option === item.option)

    // current quantity of that item in cart
    const currentQty = selectedCartItem.quantity

    if (currentQty > 1) {
      // reduce by one
      const updatedCartItem = {
        product: { id: item.product.id },
        quantity: currentQty - 1,
        option: item.option ? item.option : '',
      }
      items[cartItemIndex] = updatedCartItem
    } else {
      // remove item
      items.splice(cartItemIndex, 1)
    }

    // save to local storage
    localStorage.setItem(
      'cart',
      JSON.stringify({
        items: items,
      })
    )

    // save to store
    dispatch({
      type: REMOVE_FROM_CART,
      items: items,
    })
  }
}

export const removeItemAndQuantitiesFromCart = (item) => {
  return async (dispatch, getState) => {
    let items = await getAsyncItems()

    const option = item.option
      ? item.option
      : ''

    // find item in cart
    const selectedCartItem = items.find((i) => i.product.id === item.product.id && i.option === item.option)

    // find index
    const cartItemIndex = items.findIndex((i) => i.product.id === item.product.id && i.option === item.option)

    items.splice(cartItemIndex, 1)

    // save to local storage
    localStorage.setItem(
      'cart',
      JSON.stringify({
        items: items,
      })
    )

    // save to store
    dispatch({
      type: REMOVE_FROM_CART,
      items: items,
    })
  }
}

export const addItemToSavedList = (item) => {
  return async (dispatch, getState) => {
    let savedItems = await getAsyncSavedItems()

    // create new saved item
    const newSavedItem = {
      product: { id: item.product.id },
      quantity: 1,
      option: item.option ? item.option : '',
    }

    savedItems.push(newSavedItem)

    const newSavedList = [...new Set(savedItems)]

    // save to local storage
    localStorage.setItem(
      'saved',
      JSON.stringify({ savedItems: newSavedList })
    )

    // save to store
    dispatch({
      type: ADD_TO_SAVED_LIST,
      savedItems: newSavedList,
      cartMessage: null,
    })
  }
}

export const removeItemFromSavedList = (item) => {
  return async (dispatch, getState) => {
    let savedItems = await getAsyncSavedItems()

    // remove item from saved
    const index = savedItems.findIndex((i) => i.product.id === item.product.id && i.option === item.option)

    savedItems.splice(index, 1)

    // save to local storage
    localStorage.setItem(
      'saved',
      JSON.stringify({ savedItems: savedItems })
    )

    // save to store
    dispatch({
      type: REMOVE_FROM_SAVED_LIST,
      savedItems: savedItems,
    })
  }
}

export const checkCart = () => {
  return async (dispatch, getState) => {
    let items = getState().cart.items

    // if state is empty, check local storage
    if (items.length === 0) items = await getAsyncItems()

    items && items.forEach((item) => {
      const quantityOrdered = item.quantity
      const s = quantityOrdered > 1 ? 's' : ''
      const productId = item.product.id
      const url = 'https://powershopz.com/php/products/findProduct.php'
      const data = { productId }

      // SQL
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then(res => res.json())
        .then(data => {
          if (data) {
            const storeName = data.storeName ? data.storeName : 'This vendor'
            const title = data.title ? data.title : 'item'
            const quantityAvailable = data.quantity ? data.quantity : 0
            const qty = quantityAvailable === 0 ? 'None' : `Only ${quantityAvailable}`
            const are = quantityAvailable === 1 ? 'is' : 'are'

            if (quantityOrdered > quantityAvailable && !isDownload(data)) {
              return `${storeName} no longer has ${quantityOrdered} ${title}${s}.\n${qty} ${are} available.\nPlease make adjustments to your cart.`
            } else {
              return null
            }
          } else {
            removeItemAndQuantitiesFromCart(item)
            return `This product is no longer available.\nPlease make adjustments to your cart.`
          }
        })
        .then((res) => dispatch({ type: CHECK_CART, checkCartMessage: res }))
        .catch((e) => console.log(`Error checking cart product\n${e.message}`))
    })
  }
}

export const setCart = () => {
  return async (dispatch, getState) => {
    let items = await getAsyncItems()

    dispatch({
      type: SET_CART,
      items: items,
    })
  }
}

export const clearCart = () => {
  return async (dispatch, getState) => {
    dispatch({ type: CLEAR_CART })
  }
}

export const setSaved = () => {
  return async (dispatch, getState) => {
    let savedItems = await getAsyncSavedItems()

    dispatch({
      type: SET_SAVED,
      savedItems: savedItems,
    })
  }
}
