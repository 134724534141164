export const DELETE_REQUEST = 'DELETE_REQUEST'
export const GET_REQUESTS = 'GET_REQUESTS'

export const deleteRequest = (id) => {
    return async (dispatch) => {
        const url = 'https://powershopz.com/php/requests/deleteRequest.php'
        const data = { id: id }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then(() => {
                dispatch({
                    type: DELETE_REQUEST,
                    message: 'success!',
                })
            })
            .catch((e) => {
                console.log(`Error deleting request\n${e.message}`)
                dispatch({
                    type: DELETE_REQUEST,
                    message: `Error deleting request\n${e.message}`
                })
            })
    }
}

export const getRequests = () => {
    return async (dispatch) => {
        const url = 'https://powershopz.com/php/requests/getRequests.php'
        const requests = []

        fetch(url, {
            method: 'POST',
        })
            .then(res => res.json())
            .then(data => data.map(i => requests.push(i)))
            .then(() => {
                dispatch({
                    type: GET_REQUESTS,
                    data: requests,
                })
            })
            .catch((e) => {
                console.log(`Error getting requests\n${e.message}`)
                dispatch({
                    type: GET_REQUESTS,
                    message: `Error getting request\n${e.message}`
                })
            })
    }
}
