import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { MdLanguage } from "react-icons/md"
import { isMobile } from 'react-device-detect'
import { MdSearch, MdSettings } from "react-icons/md"
import { Link, NavLink, useLocation } from 'react-router-dom'
import logo from '../assets/images/logo.png'
import cartImg from '../assets/images/cartCropped.png'
import logo_mobile from '../assets/images/logo_mobile.png'
import Colors from '../constants/Colors'
import BurgerMenu from './BurgerMenu'
import i18n from '../src/i18n'
import { FaChevronCircleLeft } from "react-icons/fa"


const NavbarAnimated = (props) => {
  const location = useLocation()

  const [num, setNum] = useState(0)

  const getCartData = async () => {
    const cartData = localStorage.getItem('cart')
    if (cartData === null) {
      setNum(0)
    } else {
      const transformedData = JSON.parse(cartData)
      const { items } = transformedData
      let count = 0
      items.forEach((item) => {
        count += item.quantity
      })
      setNum(count)
    }
  }

  useEffect(() => {
    getCartData()
  }, [location.pathname, props.cart])

  return (
    <div style={styles.main} className='menu'
    >
      <BurgerMenu num={num} />
      <div style={styles.header}>
        <div style={styles.logoAndGoBack}>

          {!isMobile &&
            <FaChevronCircleLeft
              style={{ cursor: 'pointer' }}
              color='white'
              size={30}
              onClick={() => history.back()}
            />
          }

          {location.pathname === '/'
            ? <img
              src={isMobile
                ? logo_mobile
                : logo
              }
              style={styles.logo}
            />
            : <div title={i18n.t('nav.home')}>
              <Link
                to={'/'}
                style={{ textDecorationLine: 'none' }}
              >
                <img
                  src={isMobile
                    ? logo_mobile
                    : logo
                  }
                  style={styles.logo}
                />
              </Link>
            </div>
          }
        </div>

        <div style={styles.navButtons}>
          {window.innerWidth > 360 &&
            <NavLink
              to="/language"
              style={styles.icon}
            >
              <div title={i18n.t('main.change_lan')} placement="bottom">
                <MdLanguage size={isMobile ? 19 : 31} color="white" />
              </div>
            </NavLink>
          }

          <NavLink
            to="/search"
            style={styles.icon}
          >
            <div title={i18n.t('nav.search')} placement="bottom">
              <MdSearch size={isMobile ? 24 : 36} color="white" />
            </div>
          </NavLink>

          <NavLink
            to={'/login'}
            style={styles.icon}
          >
            <div title={i18n.t('nav.your_admin')} >
              <MdSettings size={isMobile ? 21 : 34} color={'white'} />
            </div>
          </NavLink>

          <NavLink to={'/cart'} style={styles.icon3}>
            <div title={i18n.t('nav.cart')}>
              <img src={cartImg} style={styles.cart} />
              <div style={num === 0 ? styles.hide : styles.counter}>
                <p style={styles.counterText}>{num}</p>
              </div>
            </div>
          </NavLink>
        </div>
      </div>
    </div>

  )
}

const styles = {
  main: {
    position: 'fixed',
    top: 0,
    backgroundColor: Colors.menuBlue,
    width: '100%',
    padding: isMobile ? 20 : 30,
    paddingTop: 10,
    paddingBottom: 10,
    maxHeight: 75,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  logoAndGoBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  navButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginRight: 50,
  },
  counter: {
    display: 'flex',
    borderRadius: '7.5px',
    height: '15px',
    width: '15px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    transform: isMobile ? 'translateY(-25px)' : 'translateY(-35px)',
    paddingRight: 1,
    margin: 0,
    marginLeft: 12,
    boxShadow: '2px 3px rgba(0,0,0,0.25)',
    backgroundColor: 'rgba(255,255,255,0.95)',
    position: 'fixed',
  },
  counterText: {
    color: Colors.darkBlue,
    fontFamily: 'open-sans-bold',
    fontSize: '10px',
    margin: 0,
  },
  hide: {
    display: 'none',
  },
  logo: {
    width: isMobile ? 300 / 2.75 : 1000 / 4,
    height: isMobile ? 45 / 2.75 : 149 / 4,
    marginTop: isMobile ? 0 : 1,
    marginLeft: isMobile ? 0 : 20,
  },
  cart: {
    height: isMobile ? 18 : 27,
  },
  icon: {
    textDecorationLine: 'none',
    marginRight: isMobile ? 20 : 30,
    marginTop: 2,
  },
  icon3: {
    textDecorationLine: 'none',
    marginRight: isMobile ? 45 : 80,
    marginTop: isMobile ? 1 : 3,
  },
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
  }
}

export default connect(mapStateToProps)(NavbarAnimated)
