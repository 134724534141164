import { useTranslation } from 'react-i18next'
import Colors from '../constants/Colors'

const TermsModal = () => {
  const { t } = useTranslation()

  return (
    <div>
      <p style={styles.modalText}>
        <span style={styles.green}>✔</span>{t('modals.agreement_1')}</p>
      <p style={styles.modalText}>
        <span style={styles.green}>✔</span>{t('modals.agreement_2')}</p>
      <p style={styles.modalText}>
        <span style={styles.green}>✔</span>{t('modals.agreement_3')}</p>

      <p style={styles.modalText}>
        <span style={styles.green}>✔</span>{t('modals.agreement_4')}</p>

      <p style={styles.modalText}>
        <span style={styles.green}>✔</span>{t('modals.agreement_5')}</p>
      <p style={styles.italic}>{t('modals.agreement_6')}</p>
    </div>
  )
}

const styles = {
  modalText: {
    marginBottom: 15,
    textAlign: 'left',
    fontFamily: 'open-sans',
    fontSize: '0.8rem',
  },
  italic: {
    textAlign: 'center',
    fontFamily: 'open-sans',
    fontSize: '0.8rem',
    fontStyle: 'italic'
  },
  green: {
    color: Colors.green,
  }
}

export default TermsModal
