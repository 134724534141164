export default {
  primaryColor: '#9400ff',
  accentColor: '#8b3679',
  backgroundColor: '#2d283e',
  textColor: '#ddd',
  teal: '#00d8b6',
  blue: '#0074df',
  medBlue: '#183655',
  darkBlue: '#152448',
  menuBlue: '#0F1932',
  neonBlue: '#090290',
  rose: '#72264d',
  lavender: '#8b3679',
  deepPurple: '#3d1144',
  green: '#228B22',
  paleYellow: '#ffffed',
}
