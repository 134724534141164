import axios from 'axios'

export const SET_CASTING_CALL = 'SET_CASTING_CALL'
export const GET_CASTING_CALLS = 'GET_CASTING_CALL'
export const DELETE_CASTING_CALL = 'SET_CASTING_CALL'

export const setCastingCall = (data) => {
    return async (dispatch) => {
        const url = 'https://powershopz.com/php/castingCalls/setCastingCall.php'

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then(() => {
                dispatch({
                    type: SET_CASTING_CALL,
                    message: 'success!',
                })
            })
            .catch((e) => {
                console.log(`Error posting casting call\n${e.message}`)
                dispatch({
                    type: SET_CASTING_CALL,
                    message: `Error posting casting call\n${e.message}`
                })
            })
    }
}

export const getCastingCalls = (data) => {
    return async (dispatch) => {
        const url = 'https://powershopz.com/php/castingCalls/getCastingCalls.php'
        const castingCalls = []

        fetch(url, {
            method: 'POST',
        })
            .then(res => res.json())
            .then(data => data.map(i => castingCalls.push(i)))
            .then(() => {
                castingCalls.sort((a, b) => b.date - a.date)
            })
            .then(() => {
                dispatch({
                    type: GET_CASTING_CALLS,
                    data: castingCalls,
                })
            })
            .catch((e) => console.log(`Error getting casting call\n${e.message}`))
    }
}

export const deleteCastingCall = (id, image) => {
    return async (dispatch) => {
        const url = 'https://powershopz.com/php/castingCalls/deleteCastingCall.php'
        const data = { id: id }

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
        })
            .then(() => {
                dispatch({
                    type: DELETE_CASTING_CALL,
                    message: 'success!',
                })
            })
            .then(() => image && deleteCastingCallImage(image))
            .catch((e) => {
                console.log(`Error deleting casting call\n${e.message}`)
                dispatch({
                    type: DELETE_CASTING_CALL,
                    message: `Error deleting casting call\n${e.message}`
                })
            })
    }
}

const deleteCastingCallImage = (image) => {
    const deleteUrl = 'https://powershopz.com/storeImages/storeImageDelete.php'

    const pop = image.split('/storeImages/').pop()
    const data = { file: pop }

    if (data) {
        axios.post(deleteUrl, data).catch((e) => console.log(e.message))
    }
}
