import { AiOutlineCloseCircle } from "react-icons/ai"
import '../css/modal.css'

function Modal({ children, setModalVisible }) {
    return (
        <div className="modal-overlay" onClick={() => { setModalVisible(false) }}>
            <div style={{ marginTop: 50 }} className="modal-content" onClick={(e) => e.stopPropagation()}>
                {children}
                <AiOutlineCloseCircle
                    style={styles.icon}
                    onClick={() => setModalVisible(false)}
                />
            </div>
        </div>
    )
}

const styles = {
    icon: {
        position: 'absolute',
        top: 6,
        right: 5,
        fontSize: '1.5rem',
        cursor: 'pointer',
    }
}

export default Modal
