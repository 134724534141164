import { combineReducers } from 'redux'
// import { firebaseReducer } from 'react-redux-firebase'

import categoriesReducer from './categories'
import loginReducer from './login'
import productsReducer from './products'
import storesReducer from './stores'
import cartReducer from './cart'
import ordersReducer from './orders'
import coinsReducer from './coins'
import promoReducer from './promo'
import requestsReducer from './requests'
import castingCallsReducer from './castingCalls'
import helpVideosReducer from './helpVideos'

const rootReducer = combineReducers({
  categories: categoriesReducer,
  login: loginReducer,
  products: productsReducer,
  stores: storesReducer,
  cart: cartReducer,
  orders: ordersReducer,
  coins: coinsReducer,
  promo: promoReducer,
  requests: requestsReducer,
  castingCalls: castingCallsReducer,
  helpVideos: helpVideosReducer,
  // firebase: firebaseReducer, //auth
})

export default rootReducer
