import isEmail from 'validator/lib/isEmail'

export const adminUid = 'KMe6nMUTuEN8r3SAuh6KIWemZa32'

//// To change commissions, only change const commission  & hostedCommission here and vendor.php and addOrder.php

/// Also change in functions!
export const commission = 0.15
export const hostedCommission = 0.25
export const myFee = `${commission * 100}%`
export const hostingFee = `${hostedCommission * 100}%`

export const verifyCustomerInfo = (needAddress, customerAddressInfo) => {
  if (!customerAddressInfo) return
  const name = customerAddressInfo && customerAddressInfo.name ? customerAddressInfo.name : ''
  const customerEmail = customerAddressInfo && customerAddressInfo.customerEmail ? customerAddressInfo.customerEmail : ''
  const zip = customerAddressInfo && customerAddressInfo.zip ? customerAddressInfo.zip : ''
  const country = customerAddressInfo && customerAddressInfo.country ? customerAddressInfo.country : 'United States'

  if (
    !needAddress &&
    name.trim().length > 0 &&
    isEmail(customerEmail)
  ) {
    return true
  }
  if (country !== 'United States') {
    return true
  }
  if (
    needAddress &&
    zip.trim().length > 0
  ) {
    return true
  }
  return false
}

export const emailOrderData = async ({
  id,
  orderId,
  items,
  amount,
  orderDate,
  uid,
  storeName,
  email,
  phone,
  customerInfo,
  shipping,
}) => {
  const data = {
    id: id,
    orderId: orderId,
    items: items,
    amount: amount,
    orderDate: orderDate,
    uid: uid,
    storeName: storeName,
    email: email,
    phone: phone,
    customerInfo: customerInfo,
    shipping: shipping,
  }

  const vendorURL = 'https://powershopz.com/php/vendor.php'

  await fetch(vendorURL, {
    method: 'POST',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    body: JSON.stringify(data),
  }).catch((e) => alert(`Error emailOrderData\n${e.message}`))
}

export const emailCustomer = async (customerInfo) => {
  const customerURL = 'https://powershopz.com/php/emails/emailCustomer.php'

  await fetch(customerURL, {
    method: 'POST',
    body: JSON.stringify(customerInfo),
  })
    .catch((e) => alert(`Error emailCustomer\n${e.message}`))
}

export const emailContactData = async ({ name, email, message, date }) => {
  const data = {
    name: name,
    email: email,
    message: message,
    date: date,
  }

  const contactURL = 'https://powershopz.com/php/contact.php'

  await fetch(contactURL, {
    method: 'POST',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
    body: JSON.stringify(data),
  }).catch((e) => alert(`Error emailContactData\n${e.message}`))
}

export const emailVendorPaid = async (order) => {
  const vendorPaidURL = 'https://powershopz.com/php/emails/vendorPaid.php'

  await fetch(vendorPaidURL, {
    method: 'POST',
    body: JSON.stringify(order),
  }).catch((e) => alert(`Error: emailVendorPaid\n${e.message}`))
}

export const getIsHosted = (order) => {
  const orderItems = order ? order.items : []

  let isHosted = false
  for (const key in orderItems) {
    if (parseInt(orderItems[key].hosted) === 1) {
      isHosted = true
    }
    return isHosted
  }
}

export const emailCustomerSent = async (order, partial) => {
  const customerSentURL = partial
    ? 'https://powershopz.com/php/emails/customerPartialSent.php'
    : 'https://powershopz.com/php/emails/customerSent.php'

  const errorMessage = `Error emailCustomerSent partial: ${partial}`

  await fetch(customerSentURL, {
    method: 'POST',
    body: JSON.stringify(order),
  }).catch((e) => alert(`${errorMessage}\n${e}`))
}

const emailCustomerPaid = async (order) => {
  const emailCustomerPaidUrl =
    'https://powershopz.com/php/emails/emailCustomerPaid.php'

  await fetch(emailCustomerPaidUrl, {
    method: 'POST',
    body: JSON.stringify(order),
  }).catch((e) => alert(`Error emailCustomerPaid\n${e.message}`))
}

export const shouldSendDownloadLinksToCustomer = (order) => {
  let shippingTotal = 0
  let downloadLinks = 0
  let productsWithoutLinks = 0
  let returnValue = true

  order &&
    order.shipping &&
    order.shipping.forEach((element) => {
      shippingTotal += element.value
    })

  order && order.items &&
    order.items.forEach((item) => {
      const isDownload = item.category === 'videos' || item.category === 'photos'
        ? true
        : false
      isDownload
        ? downloadLinks++
        : productsWithoutLinks++
    })

  const data = { ...order, shippingTotal }

  if (downloadLinks > 0 && productsWithoutLinks === 0) {
    emailCustomerSent(data, false)
    // mark as sent
    returnValue = true
  } else if (downloadLinks > 0 && productsWithoutLinks > 0) {
    emailCustomerSent(data, true)
    // do not mark as sent
    returnValue = false
  } else {
    emailCustomerPaid(data)
    // do not send links
    // do not mark as sent
    returnValue = false
  }
  return returnValue
}

export const emailVendorProductAllOut = async (data) => {
  const allOutUrl = 'https://powershopz.com/php/allOut.php'

  await fetch(allOutUrl, {
    method: 'POST',
    body: JSON.stringify(data),
  }).catch((e) => alert(`Error emailVendorProductAllOut\n${e.message}`))
}

export const emailSalesData = async (data, month, year, isAdmin) => {
  const salesDataUrl = isAdmin
    ? 'https://powershopz.com/php/salesData/PowershotzSalesData.php'
    : 'https://powershopz.com/php/salesData/salesData.php'

  const dataChunk = {
    data: data,
    month: month,
    year: year,
  }

  await fetch(salesDataUrl, {
    method: 'POST',
    body: JSON.stringify(dataChunk),
  }).catch((e) => alert(`Error emailSalesData\n${e.message}`))
}

export const emailShippingError = async (data) => {
  const shippingErrorUrl = 'https://powershopz.com/php/shippingError.php'

  await fetch(shippingErrorUrl, {
    method: 'POST',
    body: JSON.stringify(data),
  }).catch((e) => alert(`Error emailShippingError\n${e.message}`))
}

export const emailQuestion = async (data) => {
  const questionURL = 'https://powershopz.com/php/askAQuestion.php'

  await fetch(questionURL, {
    method: 'POST',
    body: JSON.stringify(data),
  }).catch((e) => alert(`Error asking question\n${e.message}`))
}
