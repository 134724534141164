class Store {
  constructor(
    id,
    uid,
    email,
    phone,
    storeName,
    image,
    description,
    coinAbbr,
    coinAddress,
    coinName,
    hosted,
    date,
    zip,
    city,
    state,
    country,
    earnings,
    syncFolder,
    numProducts,
    isPublished
  ) {
    this.id = id
    this.uid = uid
    this.email = email
    this.phone = phone
    this.storeName = storeName
    this.image = image
    this.description = description
    this.coinAbbr = coinAbbr
    this.coinAddress = coinAddress
    this.coinName = coinName
    this.hosted = hosted
    this.date = date
    this.zip = zip
    this.city = city
    this.state = state
    this.country = country
    this.earnings = earnings
    this.syncFolder = syncFolder
    this.numProducts = numProducts
    this.isPublished = isPublished
  }
}

export default Store
