import dayjs from 'dayjs'

export const SET_STORE = 'SET_STORE'
export const FETCH_STORES = 'FETCH_STORES'
export const TEMP_LOGO = 'TEMP_LOGO'
export const FIND_STORE = 'FIND_STORE'
export const SET_MESSAGE = 'SET_MESSAGE'

export const formatStoreData = (store) => {
  const data = {
    ...store,
    date: dayjs(store.date).format('MMMM DD, YYYY'),
    unformattedDate: store.date,
    earnings: parseFloat(store.earnings),
    fee: parseFloat(store.fee),
    hosted: parseInt(store.hosted),
    isPublished: parseInt(store.isPublished),
    latestProduct: dayjs(store.latestProduct).format('MMMM DD, YYYY'),
    unformattedLatestProduct: store.latestProduct,
    numProducts: parseInt(store.numProducts),
  }
  return data
}

export const tempLogo = (img) => {
  return { type: TEMP_LOGO, img: img }
}

export const getAllStores = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/stores/getAllStores.php'
    const stores = []

    fetch(url, {
      method: 'POST',
    }).then(res => res.json())
      .then(data => data.map(i => stores.push(formatStoreData(i))))
      .then(() => dispatch({
        type: FETCH_STORES,
        stores: stores,
      }))
      .catch((e) => console.log(`Error getting all stores\n${e.message}`))
  }
}

export const getAllStoresLimitData = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/stores/getAllStoresLimitData.php'
    const stores = []

    fetch(url, {
      method: 'POST',
    }).then(res => res.json())
      .then(data => data.map(i => stores.push(formatStoreData(i))))
      .then(() => dispatch({
        type: FETCH_STORES,
        stores: stores,
      }))
      .catch((e) => console.log(`Error getting all stores\n${e.message}`))
  }
}

export const getAllPublishedStores = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/stores/getAllPublishedStores.php'
    const stores = []


    fetch(url, {
      method: 'POST',
    }).then(res => res.json())
      .then(data => data.map(i => stores.push(formatStoreData(i))))
      .then(() => dispatch({
        type: FETCH_STORES,
        stores: stores,
      }))
      .catch((e) => console.log(`Error getting all published stores\n${e.message}`))
  }
}

export const updateStoreEarnings = (uid, amt) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/stores/updateStoreEarnings.php'
    const data = { uid, amt }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({
        type: SET_MESSAGE,
        message: 'Success updating store earnings'
      }))
      .catch((e) => console.log(`Error updating store earnings\n${e.message}`))
  }
}

export const changeStoreEarnings = (uid, amt) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/stores/changeStoreEarnings.php'
    const data = { uid, amt }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({
        type: SET_MESSAGE,
        message: 'Success changing store earnings'
      }))
      .catch((e) => console.log(`Error changing store earnings\n${e.message}`))
  }
}

export const findStore = (uid) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/stores/findStore.php'
    const data = { uid }

    // clear data first
    dispatch({
      type: FIND_STORE,
      foundStore: {},
      storeName: ''
    })

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FIND_STORE,
          foundStore: formatStoreData(data),
          storeName: data.storeName,
        })
      })
      .catch((e) => {
        console.log(`Error finding store\n${e.message}`)
        fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
        })
          .then(res => res.json())
          .then(data => dispatch({
            type: FIND_STORE,
            foundStore: formatStoreData(data),
            storeName: data.storeName,
          }))
          .catch((e) => {
            console.log(`Second failed attempt finding store\n${e.message}`)
            dispatch({
              type: FIND_STORE,
              foundStore: null,
              storeName: '',
            })
          })
      })
  }
}

export const findStoreByName = (name) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/stores/findStoreByName.php'
    const urlAllStores = 'https://powershopz.com/php/stores/getAllStores.php'

    const data = {
      name: name
        .replace(/ /g, '%')
        .replace(/%20/g, '%')
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data =>
        dispatch({
          type: FIND_STORE,
          foundStore: formatStoreData(data),
          storeName: data.storeName,
        }))
      .catch((e) => {
        console.log(`Error finding store by name (first try)\n${e.message}`)
        fetch(urlAllStores, {
          method: 'POST'
        }).then(res => res.json())
          .then(data => {
            const lcName = name.replace(/ /g, '').toLowerCase()
            const store = data.find(s => s.storeName.replace(/ /g, '').toLowerCase() === lcName)
            store && dispatch({
              type: FIND_STORE,
              foundStore: formatStoreData(store),
              storeName: store.storeName,
            })
          })
          .catch((e) => {
            console.log(`Error finding store by name\n${e.message}`)
          })
      })
  }
}

export const setStore = (data) => {
  return async (dispatch) => {
    // update store
    const url = 'https://powershopz.com/php/stores/updateStore.php'

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({
        type: SET_MESSAGE,
        message: 'Success updating store'
      }))
      .catch((e) => console.log(`Error updating store\n${e.message}`))
  }
}


export const updateIsPublished = (uid, isPublished) => {
  return async (dispatch) => {

    const url = 'https://powershopz.com/php/stores/updateIsPublished.php'
    const data = { uid, isPublished }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((resp) => !resp.ok && console.log('problem updating isPublished', resp.status))
      .catch((e) => console.log(`Error updating isPublished\n${e.message}`))
  }
}

export const updateLatestProduct = (uid) => {
  const url = 'https://powershopz.com/php/stores/updateLatestProduct.php'
  const data = { uid }

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then((resp) => resp.ok && console.log('success updating store. repsonse:', resp.status))
    .catch((e) => console.log(`Error updating latestProduct\n${e.message}`))
}

export const updateStoreDeleteCode = (uid, code) => {
  return async () => {
    const url = 'https://powershopz.com/php/stores/updateStoreDeleteCode.php'
    const data = { uid, code }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((resp) => resp.ok && console.log('success updating delete code', resp))
      .catch((e) => console.log(`Error updating delete code\n${e.message}`))
  }
}

export const correctNumProducts = (uid, numProducts) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/stores/correctNumProducts.php'

    const data = { uid, numProducts }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({
        type: SET_MESSAGE,
        message: 'Success correcting number of products'
      }))
      .catch((e) => console.log(`Error correcting number of products\n${e.message}`))
  }
}

export const setDisplayType = (uid, type) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/stores/setDisplayType.php'
    const data = { uid, type }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({
        type: SET_MESSAGE,
        message: 'Success setting display type'
      }))
      .catch((e) => console.log(`Error updating display type\n${e.message}`))
  }
}

export const setSyncFolder = (uid, newFolder) => {
  return async (dispatch, getState) => {
    const url = 'https://powershopz.com/php/stores/setSyncFolder.php'
    const data = { uid, newFolder }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({
        type: SET_MESSAGE,
        message: 'Success updating sync folder'
      }))
      .catch((e) => console.log(`Error updating sync folder\n${e.message}`))
  }
}

export const updateTags = (uid, tags) => {
  return async (dispatch, getState) => {
    const url = 'https://powershopz.com/php/stores/updateTags.php'

    const data = { uid, tags }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({
        type: SET_MESSAGE,
        message: 'Success updating tags'
      }))
      .catch((e) => console.log(`Error updating tags\n${e.message}`))
  }
}

export const updateStoreImage = (uid, url) => {
  return async (dispatch, getState) => {
    const php = 'https://powershopz.com/php/stores/updateStoreImage.php'
    const data = { uid, url }

    fetch(php, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({
        type: SET_MESSAGE,
        message: 'Success updating store image'
      }))
      .catch((e) => console.log(`Error updating store image\n${e.message}`))
  }
}

export const updateStoreBanner = (uid, url) => {
  return async (dispatch, getState) => {
    const php = 'https://powershopz.com/php/stores/updateStoreBAnner.php'
    const data = { uid, url }

    fetch(php, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => dispatch({
        type: SET_MESSAGE,
        message: 'Success updating store banner'
      }))
      .catch((e) => console.log(`Error updating store banner\n${e.message}`))
  }
}
