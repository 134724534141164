import { SET_HELP_VIDEO, GET_HELP_VIDEOS, DELETE_HELP_VIDEO } from '../actions/helpVideos'

const initialState = {
    data: null,
    message: null,
}

const helpVideosReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_HELP_VIDEO:
            return {
                ...state,
                message: action.message,
            }
        case GET_HELP_VIDEOS:
            return {
                ...state,
                data: action.data,
            }
        case DELETE_HELP_VIDEO:
            return {
                ...state,
                message: action.message,
            }
        default:
            return state
    }
}

export default helpVideosReducer
