import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Loader'
import { handleGenToast } from '../../../../components/Toast/toastComp'
import { formatPriceNoUsd } from '../../../../constants/formatPrice'
import PowerCoinzIframe from './PowerCoinzIframe'

const fetcher = (url) => fetch(url).then((res) => res.json())

const PowerCoinzBalance = (props) => {
    const { t } = useTranslation()

    const {
        seeBuy,
        setSeeBuy,
        coin,
        cartAmount,
        inputs,
        setInputs,
        thankyou = false,
    } = props

    const url = `https://data.powershotz.com/php/powercoinz/getAccount.php?email=${inputs.email}&password=${inputs.password}`

    const { data, error, isLoading } = useSWR(url, fetcher, { refreshInterval: 1000 })

    const [message, setMessage] = useState('')

    useEffect(() => {
        if ((data && data.balance >= cartAmount) || thankyou) {
            setMessage(t('coinz.have_enough'))
            setSeeBuy(false)
        } else {
            setMessage(t('coinz.not_enough'))
            setSeeBuy(true)
        }

        data && data.balance &&
            setInputs(values => ({
                ...values,
                balance: data.balance
            }))
    }, [data, cartAmount])

    const handleSeeBuy = () => {
        setSeeBuy(true)
        handleGenToast(t('coinz.please_wait'))
    }

    if (error) return <h2 style={{ textAlign: 'center' }}>{t('admin.try_again')}</h2>
    if (!data || isLoading) return <Loader />

    return (
        <div style={
            seeBuy
                ? { ...styles.main, marginBottom: -60 }
                : styles.main
        }>
            <h2 style={styles.balance}>{t('coinz.coinz_balance')} {formatPriceNoUsd(parseFloat(data.balance))}</h2>

            <p style={styles.equiv}>{t('coinz.one_one')}</p>

            {!thankyou &&
                <h3 style={
                    message === t('coinz.not_enough')
                        ? { ...styles.message, color: 'red' }
                        : { ...styles.message, color: 'green' }
                }
                >{message}</h3>
            }

            {seeBuy
                ? <PowerCoinzIframe
                    coin={coin}
                    thankyou={thankyou}
                    setSeeBuy={setSeeBuy}
                />
                : <button
                    style={styles.button}
                    onClick={handleSeeBuy}
                >
                    {t('coinz.buy_coinz')}
                </button>
            }
        </div>
    )
}

const styles = {
    main: {
        textAlign: 'center',
        marginBottom: 0,
    },
    message: {
        width: '90%',
        margin: 'auto',
        marginTop: 20,
        marginBottom: 0,
        fontWeight: 'bold'
    },
    balance: {
        margin: 0,
        fontFamily: 'open-sans-bold',
    },
    button: {
        marginTop: 25,
        paddingLeft: 15,
        paddingRight: 15,
        textTransform: 'capitalize',
        backgroundColor: 'gold',
        color: 'black',
        fontWeight: 'bold',
        fontFamily: 'open-sans-bold',
    },
    equiv: {
        margin: 5,
        fontSize: '0.9rem'
    },
}

export default PowerCoinzBalance