import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from 'firebase/auth'

export const LOGOUT = 'LOGOUT'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const AUTH_STATE = 'AUTH_STATE'

export const resetPassword = (email) => {
  const auth = getAuth()

  return async (dispatch, getState) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        dispatch({
          type: LOGIN_ERROR,
          error: `A password reset email has been sent to ${email}`,
        })
      })
      .catch((e) => {
        dispatch({ type: LOGIN_ERROR, error: e.message })
      })
  }
}

export const login = (email, password) => {
  const auth = getAuth()

  return async (dispatch, getState) => {

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        // console.log(user)

        dispatch({ type: LOGIN_SUCCESS })
      })
      // TODO: update a few passwords then disable
      .then(() => {
        if (true) {
          const url = 'https://powershopz.com/php/stores/updatePassword.php'
          const data = {
            email: email,
            password: password
          }
          fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
          })
            .catch(e => console.log('error updating password'))
        }
      })
      .catch((e) => {
        dispatch({ type: LOGIN_ERROR, error: e.message })
      })
  }
}

export const signup = (email, password) => {
  const auth = getAuth()
  return async (dispatch, getState) => {

    // creates and user and profile in 'stores'
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user

        const data = {
          email: email,
          password: password,
          uid: user.uid,
        }

        const url = 'https://powershopz.com/php/stores/addStore.php'

        fetch(url, {
          method: 'POST',
          body: JSON.stringify(data),
        })
          .then(() => console.log('Success adding store: ', data))
          .catch((e) => console.log(`Error adding store\n${e.message}`))
      })
      .then(() => dispatch({ type: LOGIN_SUCCESS }))
      .catch((e) => {
        dispatch({ type: LOGIN_ERROR, error: e.message })
      })
  }
}

export const logout = () => {
  const auth = getAuth()

  return (dispatch, getState) => {
    signOut(auth)
      .then(() => {
        dispatch({ type: LOGOUT })
      })
  }
}

export const authState = () => {
  const auth = getAuth()

  return (dispatch, getState) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // user is signed in
        const uid = user.uid
        console.log(user)
        dispatch({ type: AUTH_STATE, user: user })
      } else {
        // user is signed out
        console.log('signed out')
      }
    })
  }
}
