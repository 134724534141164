import { handleError, handleGenInfo } from '../../../../components/Toast/toastComp'
import { formatPriceNoUsd } from '../../../../constants/formatPrice'
import i18n from '../../../../src/i18n'

export const createAccount = async (data, setMessage, setStatus) => {
    const url_get = `https://data.powershotz.com/php/powercoinz/findEmail.php?email=${data.email}`

    fetch(url_get)
        .then((res) => res.json())
        .then((res) => {
            if (Object.is(res, null)) {
                // if no account exists create it
                addToDatabase(data, setMessage, setStatus)
            } else {
                // if account exists return false
                setMessage(i18n.t('coinz.email_exists'))
                setStatus(false)
            }
        })
        .catch((e) => {
            // if error return false
            console.log(`${i18n.t('coinz.error_checking_account')}\n${e.message}`)
            setMessage(i18n.t('coinz.server_error'))
            setStatus(false)
        })
}

const addToDatabase = async (data, setMessage, setStatus) => {
    const url_create = 'https://data.powershotz.com/php/powercoinz/createAccount.php'

    fetch(url_create, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((res) => {
            if (res.status === 200) {
                setStatus(true)
                setMessage(i18n.t('coinz.new_account'))
            } else {
                setStatus(false)
                setMessage(i18n.t('coinz.server_error'))
            }
        })
        .catch((e) => {
            // if error return false
            console.log(`Error creating PowerCoinz account\n${e.message}`)
            setStatus(false)
            setMessage(i18n.t('coinz.server_error'))
        })
}

export const getAccount = async (data, setMessage, setStatus, setInputs) => {
    const url_get = `https://data.powershotz.com/php/powercoinz/getAccount.php?email=${data.email}&password=${data.password}`

    fetch(url_get)
        .then((res) => res.json())
        .then((res) => {
            if (res === null) {
                // if no account throw error
                setMessage(i18n.t('coinz.login_error'))
                setStatus(false)
            } else {
                // if account exists return true
                setMessage(`${i18n.t('coinz.successful_login')} ${formatPriceNoUsd(parseFloat(res.balance))}`)
                setStatus(true)
                setInputs(values => ({
                    ...values,
                    balance: res.balance
                }))
            }
        })
        .catch((e) => {
            // if error return false
            console.log(`${i18n.t('coinz.server_error')}\n${e.message}`)
            setMessage(i18n.t('coinz.server_error'))
            setStatus(false)
        })
}

export const debitBalance = (customerInfo) => {
    if (
        !customerInfo ||
        !customerInfo.coin ||
        !customerInfo.coin.name ||
        customerInfo.coin.name !== 'coinz'
    ) return

    const coin = customerInfo.coin

    const url_create = 'https://data.powershotz.com/php/powercoinz/debitAccount.php'

    const data = {
        email: coin.email,
        password: coin.password,
        amount: customerInfo.coinPrice,
        referenceNumber: customerInfo.referenceNumber,
    }

    fetch(url_create, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((res) => {
            if (res.status === 200) {
                handleGenInfo(`${i18n.t('coinz.account_debit')} $${customerInfo.coinPrice}`)
                // mark the order paid and send it if applicable
                markPaidAndSend(data)
            } else {
                handleError('Error')
                alert(i18n.t('coinz.error_debit'))
            }
        })
        .catch((e) => {
            // if error return false
            console.log(`Error debiting PowerCoinz account\n${e.message}`)
            handleError(i18n.t('coinz.server_error'))
            alert(i18n.t('coinz.server_error'))
        })
}

const markPaidAndSend = (data) => {
    // const data = {
    //     email: coin.email,
    //     password: coin.password,
    //     amount: customerInfo.coinPrice,
    //     referenceNumber: customerInfo.referenceNumber,
    // }

    const url_send = 'https://data.powershotz.com/php/powercoinz/markPaidAndSendShopz.php'

    fetch(url_send, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((res) => {
            if (res.status === 200) {
                handleGenInfo(i18n.t('coinz.order_sent'))
            } else {
                handleError(i18n.t('coinz.error_sent'))
                alert(i18n.t('coinz.error_sent'))
            }
        })
}

export const resetPassword = (email, setMessage) => {
    const url = `https://data.powershotz.com/php/powercoinz/emailPasswordReset.php?email=${email}`

    fetch(url)
        .then((res) => {
            if (res.status === 200) {
                setMessage(`${i18n.t('coinz.email_sent')} ${email}`)
            } else {
                setMessage(`${i18n.t('coinz.email_none')} ${email}.`)
            }
        })
}

export const creditBalance = (inputs, amount) => {

    const url_create = 'https://data.powershotz.com/php/powercoinz/creditAccount.php'

    const data = {
        ...inputs,
        amount: amount
    }

    fetch(url_create, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then((res) => {
            if (res.status === 200) {
                handleGenInfo(`${i18n.t('coinz.account_credit')} $${amount}`)
            } else {
                handleError('Error')
                alert(i18n.t('coinz.error_credit'))
            }
        })
        .catch((e) => {
            // if error return false
            console.log(`Error crediting PowerCoinz account\n${e.message}`)
            handleError(i18n.t('coinz.server_error'))
            alert(i18n.t('coinz.server_error'))
        })
}