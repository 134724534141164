import Store from '../../models/storeModel'
import { LOGOUT } from '../actions/login'

import {
  SET_STORE,
  FETCH_STORES,
  TEMP_LOGO,
  FIND_STORE,
  SET_MESSAGE,
} from '../actions/stores'

const initialState = {
  stores: [],
  vendorStore: null,
  foundStore: {},
  storeName: '',
  tempLogo: {},
  message: null,
}

const storesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      }
    case LOGOUT:
      return {
        ...state,
        vendorStore: null,
      }
    case TEMP_LOGO:
      return {
        ...state,
        tempLogo: action.img,
      }
    case FETCH_STORES:
      return {
        ...state,
        stores: action.stores,
      }
    case FIND_STORE:
      return {
        ...state,
        foundStore: action.foundStore,
        storeName: action.storeName,
      }
    case SET_STORE:
      const newStore = new Store(
        action.store.uid,
        action.store.email,
        action.store.phone,
        action.store.storeName,
        action.store.image,
        action.store.description,
        action.store.coinAbbr,
        action.store.coinAddress,
        action.coinName,
        action.store.hosted,
        action.store.date,
        action.store.zip,
        action.store.city,
        action.store.state,
        action.store.country,
        action.store.earnings,
        action.store.syncFolder,
        action.store.numProducts,
        action.store.displayType,
      )
      return {
        ...state,
        stores: state.stores.concat(newStore),
        vendorStore: newStore,
      }
    default:
      return state
  }
}

export default storesReducer
