import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import translationCN from '../src/locales/cn/translation.json'
import translationDE from '../src/locales/de/translation.json'
import translationEN from '../src/locales/en/translation.json'
import translationES from '../src/locales/es/translation.json'
import translationFR from '../src/locales/fr/translation.json'
import translationIT from '../src/locales/it/translation.json'
import translationJA from '../src/locales/ja/translation.json'
import translationRU from '../src/locales/ru/translation.json'

const resources = {
    cn: {
        translation: translationCN
    },
    de: {
        translation: translationDE
    },
    en: {
        translation: translationEN
    },
    es: {
        translation: translationES
    },
    fr: {
        translation: translationFR
    },
    it: {
        translation: translationIT
    },
    ja: {
        translation: translationJA
    },
    ru: {
        translation: translationRU
    },
}
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        debug: false,
        fallbackLng: 'en',
    })

export default i18n