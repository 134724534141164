import { useFonts } from 'expo-font'
import { getAuth } from 'firebase/auth'
import loadable from '@loadable/component'
import { AuthProvider, useFirebaseApp } from 'reactfire'
import Loader from '../components/Loader'
import MetaDecorator from '../components/MetaDecorator'
import './index.css'
import { Router, Routes, Route } from './Router'
import { ToastComp } from '../components/Toast/toastComp'

//TODO: for mobile app watch https://www.youtube.com/watch?v=_CBYbEGvxYY to set up nav exports to make truly hybrid (2:11:20)

import NavbarAnimated from '../navigation/NavbarAnimated'
import PowerCoinzAdmin from '../screens/AdminScreenComponents/PowerCoinzAdmin'
import PowerCoinzScreen from '../screens/PowerCoinzScreen'
import { isMobile } from 'react-device-detect'

const CategoryScreen = loadable(() => import('../screens/shoppingScreens/CategoryScreen'), { fallback: <Loader /> })
const DetailScreen = loadable(() => import('../screens/shoppingScreens/DetailScreen'), { fallback: <Loader /> })
const ShopListScreen = loadable(() => import('../screens/shoppingScreens/ShopListScreen'), { fallback: <Loader /> })
const ShopScreen = loadable(() => import('../screens/shoppingScreens/ShopScreen'), { fallback: <Loader /> })
const CartScreen = loadable(() => import('../screens/cartScreens/CartScreen'), { fallback: <Loader /> })
const CheckoutScreen = loadable(() => import('../screens/cartScreens/CheckoutScreen'), { fallback: <Loader /> })
const ThankYouScreen = loadable(() => import('../screens/cartScreens/ThankYouScreen'), { fallback: <Loader /> })
const EditProductScreen = loadable(() => import('../screens/vendorScreens/EditProductScreen'), { fallback: <Loader /> })
const EditStoreDetailsScreen = loadable(() => import('../screens/vendorScreens/EditStoreDetailsScreen'), { fallback: <Loader /> })
const LoginScreen = loadable(() => import('../screens/vendorScreens/LoginScreen'), { fallback: <Loader /> })
const OrdersScreen = loadable(() => import('../screens/vendorScreens/OrdersScreen'), { fallback: <Loader /> })
const ShippingScreen = loadable(() => import('../screens/vendorScreens/ShippingScreen'), { fallback: <Loader /> })
const TermsScreen = loadable(() => import('../screens/vendorScreens/TermsScreen'), { fallback: <Loader /> })
const YourProductsScreen = loadable(() => import('../screens/vendorScreens/YourProductsScreen'), { fallback: <Loader /> })
const AboutScreen = loadable(() => import('../screens/AboutScreen'), { fallback: <Loader /> })
const ContactScreen = loadable(() => import('../screens/ContactScreen'), { fallback: <Loader /> })
const SearchScreen = loadable(() => import('../screens/SearchScreen'), { fallback: <Loader /> })
const Help = loadable(() => import('../screens/Help'), { fallback: <Loader /> })
const Footer = loadable(() => import('../navigation/Footer'))
const AdminScreen = loadable(() => import('../screens/AdminScreen'), { fallback: <Loader /> })
const EmailAllVendors = loadable(() => import('../screens/EmailAllVendors'))
const SaleScreen = loadable(() => import('../screens/SaleScreen'), { fallback: <Loader /> })
const PromoScreen = loadable(() => import('../screens/vendorScreens/PromoScreen'), { fallback: <Loader /> })
const CastingCallsScreen = loadable(() => import('../screens/vendorScreens/CastingCallsScreen'), { fallback: <Loader /> })
const NewestScreen = loadable(() => import('../screens/NewestScreen'), { fallback: <Loader /> })
const Dashboard = loadable(() => import('../screens/vendorScreens/Dashboard'), { fallback: <Loader /> })
const CategoryProductsScreen = loadable(() => import('../screens/CategoryProductsScreen'), { fallback: <Loader /> })
const Language = loadable(() => import('../screens/Language'), { fallback: <Loader /> })


//TODO: broadcast: https://github.com/AOHUA/redux-state-sync

const App = () => {
  const app = useFirebaseApp()
  const auth = getAuth(app)

  let [fontsLoaded, Error] = useFonts({
    'open-sans': require('../assets/fonts/OpenSans-Regular.ttf'),
    'open-sans-bold': require('../assets/fonts/OpenSans-Bold.ttf'),
    'russo-one': require('../assets/fonts/Russo-One-Regular.ttf'),
    'varino-regular': require('../assets/fonts/Varino-Regular.ttf'),
  })

  if (!fontsLoaded) {
    // fontfaceobserver.standalone.js
    // increase time on line 9
    // from 3E3 to 6E3, but it didn't work
    if (Error) {
      console.info('fonts not loaded: ', Error)
    } else {
      console.info('fonts not loaded (no error)')
    }
  }

  return (
    <AuthProvider sdk={auth}>

      <div style={styles.container}>
        <MetaDecorator />
        <ToastComp />
        <Router>
          <Routes>
            <Route path="/" element={<CategoryScreen />} />
            <Route path="/home" element={<CategoryScreen />} />

            <Route path="/shopList/:category" element={<ShopListScreen />} />
            <Route path="/shopList" element={<ShopListScreen />} />

            <Route path="/detail/:storeName/:title/" element={<DetailScreen />} />

            <Route path="/shop/:storeName/:category" element={<ShopScreen />} />
            <Route path="/shop/:storeName" element={<ShopScreen />} />

            <Route path="/cart" element={<CartScreen />} />
            <Route path="/checkout" element={<CheckoutScreen />} />

            <Route path="/thank_you/:ref" element={<ThankYouScreen />} />
            <Route path="/thank_you/" element={<ThankYouScreen />} />
            <Route path="/order/:ref" element={<ThankYouScreen />} />
            <Route path="/order/" element={<ThankYouScreen />} />

            <Route path="/edit_product/:id" element={<EditProductScreen />} />
            <Route path="/edit_product" element={<EditProductScreen />} />

            <Route path="/your_store" element={<EditStoreDetailsScreen />} />

            <Route path="/login" element={<LoginScreen />} />
            <Route path="/admin" element={<AdminScreen />} />
            <Route path="/admin/powercoinz" element={<PowerCoinzAdmin />} />

            <Route path="/orders" element={<OrdersScreen />} />
            <Route path="/dashboard/" element={<Dashboard />} />
            <Route path="/shipping" element={<ShippingScreen />} />
            <Route path="/faqs" element={<TermsScreen />} />
            <Route path="/powercoinz" element={<PowerCoinzScreen />} />

            <Route path="/your_products" element={<YourProductsScreen />} />
            <Route path="/product_report/" element={<Dashboard />} />

            <Route path="/search" element={<SearchScreen />} />
            <Route path="/about" element={<AboutScreen />} />
            <Route path="/contact" element={<ContactScreen />} />
            <Route path="/help" element={<Help />} />
            <Route path="/emailAllVendors" element={<EmailAllVendors />} />

            <Route path="/sale" element={<SaleScreen />} />
            <Route path="/newest" element={<NewestScreen />} />
            <Route path="/category/:category" element={<CategoryProductsScreen />} />

            <Route path="/promo" element={<PromoScreen />} />
            <Route path="/casting_calls" element={<CastingCallsScreen />} />
            <Route path="/:storeName/:title" element={<DetailScreen />} />
            <Route path="/:storeName" element={<ShopScreen />} />
            <Route path="/language" element={<Language />} />
            <Route element={<CategoryScreen />} />
          </Routes>
          <NavbarAnimated />
          <Footer />
        </Router>
      </div>
    </AuthProvider>
  )
}

const styles = {
  container: {
    marginTop: isMobile ? 50 : 61,
    paddingBottom: 100,
    display: 'flex',
    flex: 1,
    width: '100vw',
    justifyContent: 'flex-start',
    backgroundColor: '#f9f9f9',
  },
}

export default App
