import DROPDOWN_COINS from '../../data/altcoin'

import { FETCH_COINS } from '../actions/coins'

const initialState = {
  coins: DROPDOWN_COINS,
}

const coinsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_COINS:
      return {
        coins: action.coins,
      }
    default:
      return state
  }
}

export default coinsReducer
