import dayjs from 'dayjs'

export const getDate = (date, unformattedDate, referenceNumber) => {
    if (!date || date === '') {
        return dayjs().format('YYYY-MM-DD HH:mm:ss')
    } else if (!!date.seconds) {
        return dayjs(date.seconds * 1000).format('YYYY-MM-DD HH:mm:ss')
    } else if (date === 'Invalid date') {
        console.log('found invalid date')
        unformattedDate
            ? console.log(date, unformattedDate, referenceNumber)
            : console.log(date, referenceNumber)
    } else {
        return unformattedDate ? unformattedDate : date
    }
}

// const isDate = RegExp(/^([0-9]{4})-([0-1][0-9])-([0-3][0-9])\s([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])$/)

// const getMonth = (month) => {
//     switch (month) {
//         case 'January':
//             return '01-'
//         case 'February':
//             return '02-'
//         case 'March':
//             return '03-'
//         case 'April':
//             return '04-'
//         case 'May':
//             return '05-'
//         case 'June':
//             return '06-'
//         case 'July':
//             return '07-'
//         case 'August':
//             return '08-'
//         case 'September':
//             return '09-'
//         case 'October':
//             return '10-'
//         case 'November':
//             return '11-'
//         case 'December':
//             return '12-'
//         default:
//             return ''
//     }
// }