import { isSafari } from 'react-device-detect'
import 'animate.css'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../Modals/Modal'

const PowerCoinzDetails = (props) => {
    const { t } = useTranslation()

    const { showInfo, setShowInfo } = props

    const handleToggle = () => setShowInfo(!showInfo)

    return (
        <div>
            <div style={styles.coinzBtnContainer}>
                {!showInfo &&
                    <button
                        onClick={handleToggle}
                        style={styles.coinzButton}
                    >
                        {t('coinz.what_are')}
                    </button>
                }
            </div>

            {showInfo &&
                <Modal setModalVisible={handleToggle}>
                    <div style={styles.info}>
                        <h3 style={styles.heading}>{t('coinz.what_are')}</h3>
                        <ul>
                            <li
                                style={styles.li}
                                dangerouslySetInnerHTML={{
                                    __html: t('coinz.what_are_li_1')
                                }}
                            />

                            <li
                                style={styles.li}
                                dangerouslySetInnerHTML={{
                                    __html: t('coinz.what_are_li_2')
                                }}
                            />
                        </ul>

                        <h3 style={styles.heading}>{t('coinz.how_to_buy')}</h3>
                        <ul>
                            <li
                                style={styles.li}
                                dangerouslySetInnerHTML={{
                                    __html: t('coinz.how_to_buy_li_1')
                                }}
                            />
                            <li
                                style={styles.li}
                                dangerouslySetInnerHTML={{
                                    __html: t('coinz.how_to_buy_li_2')
                                }}
                            />
                            <li
                                style={styles.li}
                                dangerouslySetInnerHTML={{
                                    __html: t('coinz.how_to_buy_li_3')
                                }}
                            />
                        </ul>

                        <h3 style={styles.heading}>{t('coinz.why_use')}</h3>
                        <ul>
                            <li
                                style={styles.li}
                                dangerouslySetInnerHTML={{
                                    __html: t('coinz.why_use_li_1')
                                }}
                            />

                            <li
                                style={styles.li}
                                dangerouslySetInnerHTML={{
                                    __html: t('coinz.why_use_li_2')
                                }}
                            />

                            <li
                                style={styles.li}
                                className={
                                    isSafari
                                        ? ""
                                        : "animate__animated animate__pulse animate__repeat-3 animate__delay-5s"
                                }
                                dangerouslySetInnerHTML={{
                                    __html: t('coinz.why_use_li_3')
                                }}
                            />
                        </ul>


                        <h3 style={styles.summary}>{t('coinz.details_summary')}</h3>

                        <div style={styles.coinzButtonSmall}>
                            <button
                                onClick={() => setShowInfo(false)}
                                style={styles.coinzButton}
                            >
                                {t('modals.close')}
                            </button>
                        </div>
                    </div>
                </Modal>
            }
        </div>
    )
}

const styles = {
    coinzBtnContainer: {
        textAlign: 'center',
        margin: 0,
        marginBottom: 30,
    },
    coinzButton: {
        paddingLeft: 15,
        paddingRight: 15,
        backgroundColor: 'rgb(52, 152, 219)',
        cursor: 'pointer',
        color: 'white',
    },
    coinzButtonSmall: {
        width: '100%',
        textAlign: 'center',
        transform: 'scale(0.8)'
    },
    info: {
        overflow: 'auto',
        paddingBottom: 30,
        paddingRight: 20,
    },
    li: {
        fontFamily: 'open-sans',
        textAlign: 'left',
    },
    summary: {
        fontFamily: 'open-sans-bold',
        marginTop: 30,
        textAlign: 'center',
    },
    heading: {
        fontFamily: 'open-sans-bold',
    }
}

export default PowerCoinzDetails