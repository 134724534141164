import { DELETE_REQUEST, GET_REQUESTS } from '../actions/requests'

const initialState = {
    message: null,
    data: null,
}

const requestsReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_REQUEST:
            return {
                ...state,
                message: action.message
            }
        case GET_REQUESTS:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default requestsReducer
