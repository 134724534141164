import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdCloseCircleOutline } from "react-icons/io"
import PowerCoinzLogin from './PowerCoinzLogin'
import PowerCoinzBalance from './PowerCoinzBalance'

const PowerCoinz = (props) => {
    const { t } = useTranslation()

    const {
        coin = {
            image: 'https://powershopz.com/altcoin/coinz.png',
            address: 'https://square.link/u/zsp9RTt9'
        },
        setCoin = () => { },
        cartAmount,
        thankyou = false,
        showCoinz,
        setShowCoinz = () => { },
        page = false,
    } = props

    const loginInfo = localStorage.getItem('login_info')
    const savedInfo = loginInfo
        ? JSON.parse(loginInfo)
        : null

    const [loggedIn, setLoggedIn] = useState(false)
    const [seeBuy, setSeeBuy] = useState(false)
    const [checked, setChecked] = useState(
        savedInfo
            ? true
            : false
    )
    const [inputs, setInputs] = useState({
        password: savedInfo ? savedInfo.password : '',
        email: savedInfo ? savedInfo.email : '',
        balance: ''
    })

    useEffect(() => {
        setCoin({
            ...coin,
            ...inputs
        })
    }, [inputs])

    const rememberMe = () => {
        checked
            ? localStorage.removeItem('login_info')
            : localStorage.setItem('login_info', JSON.stringify({ ...inputs }))
        setChecked(!checked)
    }

    const toggleShow = () => {
        setShowCoinz(!showCoinz)
    }

    return (
        <div style={styles.main}>
            <div style={styles.title}>
                <div style={styles.coinName}>
                    <img
                        alt='PowerCoinz'
                        src={coin.image}
                        style={styles.logo}
                    />
                    {thankyou
                        ? <h2 style={styles.logoBold}>PowerCoinz</h2>
                        : <h4>PowerCoinz</h4>
                    }
                </div>
                {thankyou && !page &&
                    <IoMdCloseCircleOutline
                        onClick={toggleShow}
                        style={styles.icon}
                        size={30}
                        title={`${t('modals.close')} PowerCoinz`}
                    />
                }
            </div>

            {loggedIn
                ? <PowerCoinzBalance
                    setSeeBuy={setSeeBuy}
                    seeBuy={seeBuy}
                    coin={coin}
                    cartAmount={cartAmount}
                    inputs={inputs}
                    setInputs={setInputs}
                    thankyou={thankyou}
                />
                : <PowerCoinzLogin
                    setLoggedIn={setLoggedIn}
                    inputs={inputs}
                    setInputs={setInputs}
                    checked={checked}
                    rememberMe={rememberMe}
                    thankyou={thankyou}
                />
            }
        </div>
    )
}

const styles = {
    main: {
        backgroundColor: 'white',
        borderRadius: 5,
        boxShadow: '1px 2px 5px gray',
        marginTop: 30,
        paddingBottom: 20,
        overflow: 'hidden'
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    icon: {
        paddingRight: 20,
        cursor: 'pointer',
    },
    coinName: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    logo: {
        marginRight: 20,
        marginLeft: 20,
        height: 30,
    },
    logoBold: {
        fontFamily: 'open-sans-bold',
        fontWeight: 'bold',
        marginLeft: -10,
    },
}

export default PowerCoinz