import { SET_CASTING_CALL, GET_CASTING_CALLS, DELETE_CASTING_CALL } from '../actions/castingCalls'

const initialState = {
    data: null,
    message: null,
}

const castingCallsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CASTING_CALL:
            return {
                ...state,
                message: action.message,
            }
        case GET_CASTING_CALLS:
            return {
                ...state,
                data: action.data,
            }
        case DELETE_CASTING_CALL:
            return {
                ...state,
                message: action.message,
            }
        default:
            return state
    }
}

export default castingCallsReducer
