import React from 'react'
import HybridApp from './src/App'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { applyMiddleware, compose } from 'redux'
import { legacy_createStore as createStore } from 'redux'
import rootReducer from './store/reducers/index'
// import { getFirebase, ReactReduxFirebaseProvider } from 'react-redux-firebase'
// import { createFirestoreInstance } from 'redux-firestore'
// import firebase from './constants/ApiKeys'
import { FirebaseAppProvider } from 'reactfire'

const store = createStore(
  rootReducer,
  compose(applyMiddleware(thunk))
)

// https://react-native-community.github.io/upgrade-helper/

// https://medium.com/@jonnykalambay/your-first-hybrid-app-in-15-minutes-react-native-on-the-web-2cc2646051e

//https://dev.to/crishanks/deploy-host-your-react-app-with-cpanel-in-under-5-minutes-4mf6

//TODO: remove profile stuff
const config = {
  // userProfile: 'stores',
  // useFirestoreForProfile: true,
}

const firebaseConfig = {
  apiKey: 'AIzaSyA52FNCt0bB0CTbfqQ1TIpRjY_qrsrAsLo',
  authDomain: 'powershopz.firebaseapp.com',
  databaseURL: 'https://powershopz-default-rtdb.firebaseio.com',
  projectId: 'powershopz',
  storageBucket: 'powershopz.appspot.com',
  messagingSenderId: '821675308335',
  appId: '1:821675308335:web:5a70a4669b8293d1ac9dda',
  measurementId: 'G-1S0KPEXWVQ',
}

const App = (props) => {
  return (
    <Provider store={store}>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}      >
        <HybridApp />
      </FirebaseAppProvider>
    </Provider>
  )
}

export default App
