import { useTranslation } from 'react-i18next'
import PowerCoinz from './cartScreens/cartScreenComponents/PaymentPicker/PowerCoinz'
import Colors from '../constants/Colors'

const PowerCoinzScreen = () => {
    const { t } = useTranslation()

    return (
        <div style={styles.main}>
            <PowerCoinz thankyou={true} page={true} />
        </div>
    )
}

const styles = {
    main: {
        textAlign: 'center',
        margin: 'auto',
        marginTop: 30,
        width: '95%',
        maxWidth: 600,
    },
    link: {
        textDecorationLine: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.accentColor,
        padding: 8,
        paddingLeft: 6,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 10,
        width: 40,
        margin: 'auto'
    }
}

export default PowerCoinzScreen