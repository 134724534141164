import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { NavLink, Link } from 'react-router-dom'
import { bubble as Menu } from 'react-burger-menu'
import { MdHome, MdSearch, MdInfoOutline, MdContactMail, MdVideoLibrary, MdSettings, MdLanguage, MdOutlineOfflineBolt } from "react-icons/md"
import logo from '../assets/favicon.png'
import cartImg from '../assets/images/cartCropped.png'
import Colors from '../constants/Colors'

// ref: https://www.npmjs.com/package/react-burger-menu

// TODO: https://www.npmjs.com/package/react-contexify

const height = window.screen.height
const mobilePadding = height <= 640 ? '16px 0' : '18px 0'
const size = 24

var styles = {
    hide: {
        display: 'none'
    },
    bmBurgerButton: {
        position: 'fixed',
        width: isMobile ? 25 : 35,
        height: isMobile ? 20 : 30,
        right: isMobile ? 20 : 32,
        top: isMobile ? 15 : 16,
    },
    bmBurgerBars: {
        background: 'white',
        boxShadow: '1px 2px 3px black',
    },
    bmBurgerBarsHover: {
        background: '#a90000',
    },
    bmCrossButton: {
        height: '24px',
        width: '24px',
        top: 10,
        right: 10,
    },
    bmCross: {
        background: '#474747',
    },
    bmMenuWrap: {
        position: 'fixed',
        top: isMobile ? 50 : 62,
    },
    bmMenu: {
        background: Colors.menuBlue,
        padding: isMobile
            ? '2em 1.5em 0 1.5em'
            : '2.5em 2.5em 0 1.5em',
        overflow: 'auto',
        width: '100%',
    },
    bmMorphShape: {
        fill: Colors.menuBlue,
    },
    bmItemList: {
        color: '#b8b7ad',
        padding: '0.8em',
        justifyContent: 'space-between',
        height: 'auto',
    },
    bmItem: {
        display: 'block',
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.5)',
        marginTop: isMobile ? 40 : 52,
        marginLeft: -30,
        minWidth: '100%'
    },
    counter: {
        color: Colors.darkBlue,
        fontSize: '11px',
        borderRadius: '7.5px',
        borderColor: Colors.menuBlue,
        borderWidth: 1,
        height: '16px',
        width: '16px',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        transform: 'translateY(-35px)',
        paddingRight: 1,
        margin: 0,
        marginLeft: '6px',
        boxShadow: '2px 3px rgba(0,0,0,0.25)',
        backgroundColor: 'rgba(255,255,255,0.95)',
        position: 'fixed',
        fontFamily: 'open-sans-bold',
    },
    menuItem: {
        fontSize: isMobile ? '1.2rem' : '1.3rem',
        display: 'block',
        margin: isMobile ? mobilePadding : '14px 0',
        color: '#bdc3c7',
        textDecorationLine: 'none',
        fontFamily: 'open-sans-bold',
    },
    activeStyle: {
        fontSize: isMobile ? '1.2rem' : '1.3rem',
        display: 'block',
        margin: isMobile ? mobilePadding : '14px 0',
        color: '#0074DF',
        textDecorationLine: 'none',
        fontFamily: 'open-sans-bold',
    },
    navLinks: {
        marginTop: isMobile ? -20 : 20,
        width: '120%',
    },
    home: {
        color: '#ad35b8',
        padding: '20px',
        position: 'fixed',
        top: 15,
        right: 0,
    },
    logo: {
        width: 40,
        height: 40,
        borderBottom: '2px solid #bdc3c7',
        paddingBottom: 25,
        paddingRight: 80,
        marginTop: -30,
    },
    icon: {
        marginRight: 10,
        transform: 'translateY(4px)',
    },
    cart: {
        width: 24,
        height: 22,
        marginRight: 10,
    },
}

const BurgerMenu = (props) => {
    const { t } = useTranslation()

    const [state, setState] = useState({ menuOpen: false })

    const handleStateChange = (state) => {
        setState({ menuOpen: state.isOpen })
    }

    const closeMenu = () => {
        setState({ menuOpen: false })
    }

    return (
        <div>
            {/* menu */}
            <div className="navbar" id="outer-container">
                <Menu
                    styles={styles}
                    width={263}
                    isOpen={state.menuOpen}
                    onStateChange={(state) => handleStateChange(state)}
                    right
                >
                    <main id="page-wrap">
                        <div>
                            {!isMobile &&
                                <Link to="/" style={{ color: '#bdc3c7' }} onClick={closeMenu}>
                                    <img
                                        src={logo}
                                        id="logo"
                                        style={styles.logo}
                                        alt="Powershopz logo"
                                    />
                                </Link>
                            }

                            <div style={styles.navLinks}>
                                <NavLink
                                    to="/"
                                    style={({ isActive }) => (isActive ? styles.activeStyle : styles.menuItem)}
                                    onClick={closeMenu}
                                >
                                    <MdHome size={size} color="white" style={styles.icon} />
                                    {t('nav.home')}
                                </NavLink>

                                <NavLink
                                    to="/search"
                                    style={({ isActive }) => (isActive ? styles.activeStyle : styles.menuItem)}
                                    onClick={closeMenu}
                                >
                                    <MdSearch size={size} color="white" style={styles.icon} />
                                    {t('nav.search')}
                                </NavLink>

                                <NavLink
                                    to="/about"
                                    style={({ isActive }) => (isActive ? styles.activeStyle : styles.menuItem)}
                                    onClick={closeMenu}
                                >
                                    <MdInfoOutline size={size} color="white" style={styles.icon} />
                                    {t('nav.about')}
                                </NavLink>

                                <NavLink
                                    to="/contact"
                                    style={({ isActive }) => (isActive ? styles.activeStyle : styles.menuItem)}
                                    onClick={closeMenu}
                                >
                                    <MdContactMail size={size} color="white" style={styles.icon} />
                                    {t('nav.contact')}
                                </NavLink>

                                <NavLink
                                    to="/cart"
                                    style={({ isActive }) => (isActive ? styles.activeStyle : styles.menuItem)}
                                    onClick={closeMenu}
                                >
                                    <img
                                        src={cartImg}
                                        id="cart"
                                        style={styles.cart}
                                        alt="Powershopz cart"
                                    />
                                    {!isMobile && <div style={props.num === 0 ? styles.hide : styles.counter}>
                                        {props.num}
                                    </div>}
                                    {t('nav.cart')} {isMobile ? `(${props.num})` : ''}
                                </NavLink>

                                <NavLink
                                    to="/help"
                                    style={({ isActive }) => (isActive ? styles.activeStyle : styles.menuItem)}
                                    onClick={closeMenu}
                                >
                                    <MdVideoLibrary size={size} color="white" style={styles.icon} />
                                    {t('nav.help')}
                                </NavLink>

                                <NavLink
                                    to="/login"
                                    style={({ isActive }) => (isActive ? styles.activeStyle : styles.menuItem)}
                                    onClick={closeMenu}
                                >
                                    <MdSettings size={size} style={styles.icon} color={'white'} />
                                    {t('nav.your_admin')}
                                </NavLink>

                                <NavLink
                                    to="/language"
                                    style={({ isActive }) => (isActive ? styles.activeStyle : styles.menuItem)}
                                    onClick={closeMenu}
                                >
                                    <MdLanguage size={size} style={styles.icon} color={'white'} />
                                    {t('nav.language')}
                                </NavLink>

                                <NavLink
                                    to="/powercoinz"
                                    style={({ isActive }) => (
                                        isActive
                                            ? styles.activeStyle
                                            : styles.menuItem
                                    )}
                                    onClick={closeMenu}
                                >
                                    <MdOutlineOfflineBolt size={26} style={{ ...styles.icon, transform: 'rotate(20deg) translateY(4px)' }} color={'white'} />
                                    PowerCoinz
                                </NavLink>
                            </div>
                        </div>
                    </main>
                </Menu>
            </div>
        </div>
    )
}

export default BurgerMenu
