export const structuredProduct = (
    title,
    image,
    description,
    url,
    price,
    storeName,
    logo,
) => {
    const date = new Date(new Date()
        .setFullYear(new Date()
            .getFullYear() + 1))

    return `[{
        "@context": "http://schema.org",
        "@type": "Product",
        "name": "${title}",
        "image": "${image}",
        "description": "${description.replace(/"/g, '').replace(/-/g, ' ').slice(0, 4999)}",
        "url": "${url}",
        "brand": {
            "@type": "Brand",
            "name": "${storeName}",
            "logo": "${logo}"
        },
        "offers": {
            "@type": "Offer",
            "price": "${price}",
            "availability": "http://schema.org/InStock",
            "priceCurrency": "USD",
            "priceValidUntil": "${date}",
            "url" : "${url}",
            "image": "${image}",
            "name" : "${title}",
            "description": "${description.replace(/"/g, '').replace(/-/g, ' ').slice(0, 4999)}"
        }
        }]`
}

export const structuredOrganization = `[{
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://powershopz.com",
        "logo": "https://powershopz.com/favicon.png"
        }]`
