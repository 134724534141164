export const FETCH_COINS = 'FETCH_COINS'


export const fetchCoins = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/coins/fetchCoins.php'
    const coins = []

    fetch(url, {
      method: 'POST',
    })
      .then(res => res.json())
      .then(data => data.map(i => coins.push(i)))
      .then(() => {
        dispatch({
          type: FETCH_COINS,
          coins: coins,
        })
      })
      .catch((e) => console.log(`Error loading altcoins\n${e.message}`))
  }
}
