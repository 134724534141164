export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'

export const updateCategory = (category, qty) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/categories/categoriesUpdate.php'
    const data = { category, qty }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => console.log(`Error updating categories\n${e.message}`))
  }
}

export const categoryQuantityChange = (category, qty) => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/categories/categoryQuantityChange.php'
    const data = { category, qty }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => console.log(`Error changing category sales\n${e.message}`))
  }
}


export const getCategoriesSql = () => {
  return async (dispatch) => {
    const url = 'https://powershopz.com/php/categories/categories.php'
    const allCategories = []

    fetch(url, {
      method: 'POST',
    })
      .then(res => res.json())
      .then(data => data.map(i => allCategories.push(i)))
      .then(() => {
        dispatch({
          type: FETCH_CATEGORIES,
          categories: allCategories,
        })
      })
      .catch((e) => console.log(`Error posting categories\n${e.message}`))
  }
}