import dayjs from 'dayjs'
import { getVendorAmount } from '../../components/adminComponents/getVendorAmount'
import { getDate } from '../../functions/getDate'
import * as categoryActions from './categories'
import * as productActions from './products'
import * as storesActions from './stores'

export const ADD_ORDER = 'ADD_ORDER'
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDER = 'FETCH_ORDER'
export const FETCH_ALL_CUSTOMER_ORDERS = 'FETCH_ALL_CUSTOMER_ORDERS'
export const FETCH_VENDOR_ORDERS = 'FETCH_VENDOR_ORDERS'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const ADMIN_FETCH_ORDERS = 'ADMIN_FETCH_ORDERS'
export const DELETE_ORDER = 'DELETE_ORDER'

export const formatOrderData = (order) => {
  const cartItems = order.cartItems && order.cartItems.replace(/\n/g, '\\n')
  const tracking = order.tracking && order.tracking.replace(/\n/g, '\\n')
  const shipping = order.shipping && order.shipping.replace(/\n/g, '\\n')

  const data = {
    ...order,
    orderDate: dayjs(order.orderDate).format('dddd, MMMM DD, YYYY'),
    unformattedDate: order.orderDate,
    amount: parseFloat(order.amount),
    totalAmount: parseFloat(order.totalAmount),
    totalShipping: parseFloat(order.totalShipping),
    cartItems: JSON.parse(cartItems),
    items: JSON.parse(order.items),
    tracking: JSON.parse(tracking),
    shipping: JSON.parse(shipping),
    vendorAmount: parseFloat(order.vendorAmount),
    myFee: parseFloat(order.myFee),
  }
  return data
}

const formatCustomerOrderData = (order) => {
  const data = {
    ...order,
    orderDate: dayjs(order.orderDate).format('dddd, MMMM DD, YYYY'),
    totalAmount: parseFloat(order.totalAmount),
  }
  return data
}

export const fetchAllOrders = () => {
  return async (dispatch, getState) => {
    const url = 'https://powershopz.com/php/orders/fetchAllOrders.php'
    const orders = []

    fetch(url, {
      method: 'POST',
    }).then(res => res.json())
      .then(data => {
        data.map(i => {
          orders.push(formatOrderData(i))
        })
      })
      .then(() => {
        dispatch({
          type: FETCH_ORDERS,
          orders: orders,
        })
      })
      .catch((e) => console.log(`Error fetching all orders\n${e.message}`))
  }
}

export const fetchCustomerOrder = (ref) => {
  const url = 'https://powershopz.com/php/orders/ee6f2f683b34a8669f3078c0136fd4dd9dd66650.php'
  const data = { ref }
  const orders = []

  return async (dispatch, getState) => {
    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => {
        data.map(i => {
          orders.push(formatOrderData(i))
        })
      })
      .then(() => {
        dispatch({
          type: FETCH_ORDER,
          customerOrder: orders,
        })
      })
      .catch((e) => {
        console.log(`Error finding customer order\n${e.message}`)
        dispatch({
          type: FETCH_ORDER,
          customerOrder: null,
        })
      })
  }
}

export const fetchAllCustomerOrders = (email) => {
  const url = 'https://powershopz.com/php/orders/fetchAllCustomerOrders.php'
  const data = { email }
  const orders = []

  return async (dispatch, getState) => {
    // SQL
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(res => res.json())
      .then(data => {
        data.map(i => {
          orders.push(formatCustomerOrderData(i))
        })
      })
      .then(() => {
        dispatch({
          type: FETCH_ALL_CUSTOMER_ORDERS,
          allCustomerOrders: orders,
        })
      })
      .catch((e) => {
        console.log(`Error finding all customer orders\n${e.message}`)
        dispatch({
          type: FETCH_ALL_CUSTOMER_ORDERS,
          allCustomerOrders: null,
        })
      })
  }
}

export const fetchVendorOrders = (uid) => {
  return async (dispatch, getState) => {
    const url = 'https://powershopz.com/php/orders/fetchVendorOrders.php'
    const orders = []
    const data = { uid }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    }).then(res => res.json())
      .then(data => {
        data.map(i => orders.push(formatOrderData(i)))
      })
      .then(() => {
        dispatch({
          type: FETCH_VENDOR_ORDERS,
          vendorOrders: orders,
        })
      })
      .catch((e) => console.log(`Error fetching vendor orders\n${e.message}`))
  }
}

export const addOrder = (order) => {
  return async (dispatch, getState) => {
    const stores = getState().stores.stores

    const { vendorAmount, fee } = getVendorAmount(order, stores)

    const orderDate = getDate(order.orderDate, order.unformattedDate, order.referenceNumber)

    // TODO: added new for new checkout
    const url = 'https://powershopz.com/php/orders/addOrder.php'

    const data = {
      ...order,
      cartItems: JSON.stringify(order.cartItems),
      items: JSON.stringify(order.items),
      shipping: order.shipping ? JSON.stringify(order.shipping) : "[]",
      tracking: order.tracking ? JSON.stringify(order.tracking) : "[]",
      vendorAmount: vendorAmount ? vendorAmount : 0,
      myFee: fee ? fee : 0,
      orderDate: orderDate,
      customerInfo: {
        ...order.customerInfo,
        totalShipping: order.customerInfo.totalShipping ? order.customerInfo.totalShipping : 0,
      }
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => { console.log('Error adding order', e.message) })
  }
}

export const updateOrderSent = (updatedOrder) => {
  return async (dispatch, getState) => {
    const deliverDate =
      updatedOrder.sent === 'pending'
        ? dayjs().format('MMMM DD, YYYY hh:mm a')
        : updatedOrder.sent

    const data = {
      sent: deliverDate,
      id: updatedOrder.id,
    }

    const url = 'https://powershopz.com/php/orders/updateOrderSent.php'

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: UPDATE_ORDER,
          order: { ...updatedOrder, sent: deliverDate },
        })
      })
      .catch((e) => { console.log('Error updating order sent date', e.message) })
  }
}

export const updateCustomerPaidForOrder = (updatedOrder) => {
  return async (dispatch, getState) => {

    const data = {
      paid: updatedOrder.paid,
      id: updatedOrder.id,
      sent: updatedOrder.sent,
    }

    const url = 'https://powershopz.com/php/orders/updateCustomerPaidForOrder.php'

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: UPDATE_ORDER,
          order: updatedOrder,
        })
      })
      .then(() => {
        // track store order amounts
        dispatch(
          storesActions.updateStoreEarnings(updatedOrder.uid, updatedOrder.amount)
        )
      })
      .then(() => {
        // track category sales
        // update product sold
        updatedOrder.items.forEach((item) => {
          dispatch(categoryActions.updateCategory(item.category, item.quantityOrdered))
          dispatch(productActions.updateSale(item.id, item.quantityOrdered))
        })
      })
      .catch((e) => {
        alert(`Error updating customer paid for order status.\n${e.message}`)
      })
  }
}

export const updateVendorStatus = (updatedOrder) => {
  return async (dispatch, getState) => {
    const paidDate = dayjs().format('MMMM DD, YYYY hh:mm a')

    const data = {
      vendorPaid: paidDate,
      id: updatedOrder.id,
    }

    const url = 'https://powershopz.com/php/orders/updateVendorPaidStatus.php'

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: UPDATE_ORDER,
          order: { ...updatedOrder, vendorPaid: paidDate },
        })
      })
      .catch((e) => {
        alert(`Error updating vendor paid status.\n${e.message}`)
      })
  }
}

export const updatePaymentId = (orderId, paymentId) => {
  return async (dispatch, getState) => {

    const data = {
      orderId: orderId,
      paymentId: paymentId,
    }

    const url = 'https://powershopz.com/php/orders/updatePaymentId.php'

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        console.log('success updating paymentId')
      })
      .catch((e) => {
        alert(`Error updating paymentId\n${e.message}`)
      })
  }
}

export const updateOrderTracking = (updatedOrder) => {
  return async (dispatch, getState) => {
    const data = {
      tracking: JSON.stringify(updatedOrder.tracking),
      id: updatedOrder.id,
    }

    const url = 'https://powershopz.com/php/orders/updateOrderTracking.php'

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then(() => {
        dispatch({
          type: UPDATE_ORDER,
          order: updatedOrder,
        })
      })
      .catch((e) => {
        alert(`Error updating vendor order tracking.\n${e.message}`)
      })
  }
}

export const updateMyNote = (orderId, myNote) => {
  return async (dispatch, getState) => {
    const data = {
      myNote: myNote,
      id: orderId,
    }

    const url = 'https://powershopz.com/php/orders/updateMyNote.php'

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
    })
      .catch((e) => {
        alert(`Error updating myNote\n${e.message}`)
      })
  }
}

export const deleteOrder = (order) => {
  return async (dispatch, getState) => {
    const url = 'https://powershopz.com/php/orders/deleteOrder.php'
    const data = { orderId: order.id }

    const paid = order.paid
    const uid = order.uid
    const amount = order.amount
    const items = order.items

    if (paid !== 'pending') {
      dispatch(storesActions.updateStoreEarnings(uid, -amount))
        .then(() => {
          items && items.map((item) => {
            const id = item.id
            const quantity = item.quantityOrdered
            const category = item.category

            dispatch(productActions.updateQuantity(id, quantity))
              .then(() => dispatch(productActions.updateSale(id, -quantity)))
              .then(() => dispatch(categoryActions.updateCategory(category, -quantity)))
          })
        })
        .then(() => {
          fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
          })
        })
        .then(() => dispatch({ type: DELETE_ORDER, orderId: order.id }))
        .catch((e) => alert(`Error deleting order ${order.id}.\n${e.message}`))
    } else {
      fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })
        .then(() => dispatch({ type: DELETE_ORDER, orderId: order.id }))
        .catch((e) => alert(`Error deleting order ${order.id}.\n${e.message}`))
    }
  }
}
