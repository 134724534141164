import { LOGOUT, LOGIN_ERROR, LOGIN_SUCCESS, AUTH_STATE } from '../actions/login'
import { SET_STORE } from '../actions/stores'

const initialState = {
  uid: null,
  email: null,
  user: null,
  vendorStore: null,
  storeName: null,
  error: null,
  user: null,
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        error: null,
      }
    case LOGIN_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case LOGOUT:
      return initialState
    case SET_STORE:
      return {
        ...state,
        vendorStore: action.store,
      }
    case AUTH_STATE:
      return {
        ...state,
        user: action.user
      }
    default:
      return state
  }
}

export default loginReducer
