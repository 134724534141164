import { SET_PROMO_FOLDER, GET_PROMO_FOLDERS, SET_PROMO, DELETE_PROMO, GET_PROMOS } from '../actions/promo'

const initialState = {
  message: null,
  promoFolders: null,
  promos: null,
}

const promoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMO_FOLDER:
      return {
        ...state,
        message: action.message,
      }
    case GET_PROMO_FOLDERS:
      return {
        ...state,
        promoFolders: action.promoFolders,
      }
    case SET_PROMO:
      return {
        ...state,
        message: action.message,
      }
    case DELETE_PROMO:
      return {
        ...state,
        message: action.message,
      }
    case GET_PROMOS:
      return {
        ...state,
        promos: action.promos,
      }
    default:
      return state
  }
}

export default promoReducer
