import '../css/loader.css'

const Loader = () => {
  return (
    <section className={`appLoader`}>
      <div className={`bouncingLoader`}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  )
}

export default Loader
