import Category from '../models/categoryModel'

const categories = [
  new Category(
    'everything',
    'Everything',
    'https://powershopz.com/category/everything.jpg'
  ),
  new Category(
    'videos',
    'Videos',
    'https://powershopz.com/category/videos.jpg'
  ),
  new Category(
    'newest',
    'Newest',
    'https://powershopz.com/category/newest.jpg'
  ),
  new Category(
    'photos',
    'Photos',
    'https://powershopz.com/category/photos.jpg'
  ),
  new Category(
    'smoking',
    'Smoking',
    'https://powershopz.com/category/smoking.jpg'
  ),
  new Category(
    'toys',
    'Toys',
    'https://powershopz.com/category/toys.jfif'
  ),
  new Category(
    'restraints',
    'Restraints',
    'https://powershopz.com/category/restraints.jpg'
  ),
  new Category(
    'sale',
    'Sale',
    'https://powershopz.com/category/sale.jpg'
  ),
  new Category(
    'dvds',
    'DVDs',
    'https://powershopz.com/category/dvds.jpg'
  ),
  new Category(
    'electro',
    'Electro',
    'https://powershopz.com/category/electric.jfif'
  ),
  new Category(
    'furniture',
    'Furniture',
    'https://powershopz.com/category/furniture.jpg'
  ),
  new Category(
    'knives & weapons',
    'Knives & Weapons',
    'https://powershopz.com/category/knives.png'
  ),
  new Category(
    'jewelry & accessories',
    'Jewelry & Accessories',
    'https://powershopz.com/category/jewelry.jpg'
  ),
  new Category(
    'latex',
    'Latex',
    'https://powershopz.com/category/latex.jfif'
  ),
  new Category(
    'medical',
    'Medical',
    'https://powershopz.com/category/medical.jpg'
  ),
  new Category(
    'leather',
    'Leather',
    'https://powershopz.com/category/leather.jpg'
  ),
  new Category(
    'whips & floggers',
    'Whips & Floggers',
    'https://powershopz.com/category/whips.jfif'
  ),
  new Category(
    'rope',
    'Rope',
    'https://powershopz.com/category/rope.jpg'
  ),
  new Category(
    'other',
    'Other',
    'https://powershopz.com/category/other.jpg'
  ),
  new Category(
    'vintage',
    'Vintage',
    'https://powershopz.com/category/lace.jfif'
  ),
  new Category(
    'shoes',
    'Shoes',
    'https://powershopz.com/category/shoes.jpg'
  ),
  new Category(
    'clothing',
    'Clothing',
    'https://powershopz.com/category/clothing.jpg'
  ),
  new Category(
    'classifieds',
    'Classifieds',
    'https://powershopz.com/category/classifieds.jpg'
  ),
  new Category(
    'books & magazines',
    'Books & Magazines',
    'https://powershopz.com/category/books.jpeg'
  ),
  new Category(
    'art',
    'Art',
    'https://powershopz.com/category/art.jpg'
  ),
]

export default categories
