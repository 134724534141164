import {
  DELETE_PRODUCT,
  PREVIEW_PRODUCT,
  FETCH_PRODUCTS,
  FETCH_PUBLISHED_PRODUCTS,
  FETCH_PRODUCTS_RANDOM,
  FETCH_VENDOR_PRODUCTS,
  FIND_PRODUCT,
  SET_PRODUCT,
  SALE_PRODUCTS,
  NEWEST_PRODUCTS,
  FIND_PRODUCT_BY_TITLE,
  FETCH_CATEGORY_PRODUCTS,
  FETCH_CLASSIFIEDS,
} from '../actions/products'

import { LOGOUT } from '../actions/login'

const initialState = {
  products: [],
  randomProducts: [],
  vendorProducts: [],
  saleProducts: [],
  foundProduct: {},
  foundProductByTitle: {},
  previewProduct: {},
  publishedProducts: [],
  newestProducts: [],
  categoryProducts: [],
  classifieds: [],
}

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return {
        ...state,
        vendorProducts: [],
      }
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.products,
      }
    case FETCH_CATEGORY_PRODUCTS:
      return {
        ...state,
        categoryProducts: action.products,
      }
    case FETCH_PUBLISHED_PRODUCTS:
      return {
        ...state,
        publishedProducts: action.products,
      }
    case FETCH_PRODUCTS_RANDOM:
      return {
        ...state,
        randomProducts: action.products,
      }
    case SALE_PRODUCTS:
      return {
        ...state,
        saleProducts: action.saleProducts,
      }
    case NEWEST_PRODUCTS:
      return {
        ...state,
        newestProducts: action.newestProducts,
      }
    case FETCH_CLASSIFIEDS:
      return {
        ...state,
        classifieds: action.classifieds,
      }
    case FETCH_VENDOR_PRODUCTS:
      return {
        ...state,
        vendorProducts: action.vendorProducts,
      }
    case DELETE_PRODUCT:
      const filteredProducts = state.products.filter(
        (product) => product.id !== action.productId
      )
      return {
        ...state,
        products: filteredProducts,
      }
    case FIND_PRODUCT:
      return {
        ...state,
        foundProduct: action.product,
      }
    case FIND_PRODUCT_BY_TITLE:
      return {
        ...state,
        foundProductByTitle: action.product,
      }
    case PREVIEW_PRODUCT:
      // temporarily holds data for previews without changing state for anything else
      return {
        ...state,
        previewProduct: action.product,
      }
    case SET_PRODUCT:
      return {
        ...state,
        products: state.products.concat(action.product),
      }
    default:
      return state
  }
}

export default productsReducer
